import { Col, Row } from 'antd'
import { useEffect, useState } from 'react'
import CSwitch from '../../../../../components/Common/CSwitch/CSwitch'
import { InputType } from '../../../../../enum/InputType'
import { MODE } from '../../../../../enum/mode'
import { CommonError } from '../../../../../models/common/error'
import { removeLeadingZero } from '../../../../../utils'
import SectionLayout from '../SectionLayout'
import { CInput, NameAndValue } from '../Wrapper'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../app/store'
import { useSearchParams } from 'react-router-dom'

//gestion state
export interface FormGestionStateInterface {
  dluo: boolean
  dluo_value: number
  dlv: boolean
  dlv_value: number
  dlc: boolean
  dlc_value: number
  gestion: boolean
  blocage_entree: boolean
  blocage_sortie: boolean
  alerte_stock: number
  niveau_alerte: string
  variante: boolean
}

interface GestionProps {
  /**
   * update data of fournisseurs when table data changed
   * @param newValue changed data of table
   * @returns void
   */
  onChangeData?: (newValue: FormGestionStateInterface) => void
  /**
   * check valid of table when onclick sauvegader
   * @param value is valid
   * @returns void
   */
  onCheckValid?: (isValid: boolean) => void
  /**
   * array of gestion errors
   */
  gestionError?: CommonError[]
  /**
   * init gestion data
   */
  initData?: FormGestionStateInterface
  /**
   * disable if is view mode
   */
  mode: MODE
}

/**
 * The @Gestion is a component inside Reference modal
 * @author [anhnq]
 * @version 0.1
 */
const Gestion = (props: GestionProps) => {
  const stockKey = useSelector((state: RootState) => state.selector).data.stock
  const {
    onChangeData = () => {},
    onCheckValid = () => {},
    gestionError = [],
    initData,
    mode,
  } = props
  const [formState, setFormState] = useState<FormGestionStateInterface>({
    dluo: false,
    dluo_value: 0,
    dlv: false,
    dlc: false,
    dlv_value: 0,
    dlc_value: 0,
    gestion: false,
    blocage_entree: false,
    blocage_sortie: false,
    alerte_stock: 0,
    niveau_alerte: '',
    variante: false,
  })
  const [showDLUOnbr, setShowDLUOnbr] = useState<boolean>(false)
  const [showDLVnbr, setShowDLVnbr] = useState<boolean>(false)
  const [showDLCnbr, setShowDLCnbr] = useState<boolean>(false)
  const { choosingClient } = useSelector((state: RootState) => state.account)
  const [searchParams] = useSearchParams()
  const variante = searchParams.get('variante')

  console.log(choosingClient?.variantes)

  //update data of gestion when a field changes
  useEffect(() => {
    onChangeData(formState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState])

  useEffect(() => {
    if (!initData) return
    setFormState(initData)
  }, [initData])

  //update valid status
  useEffect(() => {
    let isValidDLUO = true,
      isValidDLV = true,
      isValidDLC = true
    let isValid = true

    if (showDLUOnbr) {
      if (!formState.dluo_value) isValidDLUO = false
      else isValidDLUO = true
    }
    if (showDLCnbr) {
      if (!formState.dlc_value) isValidDLC = false
      else isValidDLC = true
    }
    if (showDLVnbr) {
      if (!formState.dlv_value) isValidDLV = false
      else isValidDLV = true
    }
    isValid = isValidDLUO && isValidDLV && isValidDLC
    onCheckValid(isValid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState])

  useEffect(() => {
    if (formState.dluo) {
      setShowDLUOnbr(true)
    } else setShowDLUOnbr(false)
    if (formState.dlc) {
      setShowDLCnbr(true)
    } else setShowDLCnbr(false)
    if (formState.dlv) {
      setShowDLVnbr(true)
    } else setShowDLVnbr(false)
  }, [formState])

  ///update form when switch changes
  const onChangeSwitch = (value: boolean, field: string) => {
    setFormState({
      ...formState,
      [field]: value,
    })

    if (field === 'dluo' && !value) {
      setFormState({
        ...formState,
        dluo: false,
        dluo_value: 0,
      })
    }
    if (field === 'dlc' && !value) {
      setFormState({
        ...formState,
        dlc: false,
        dlc_value: 0,
      })
    }
    if (field === 'dlv' && !value) {
      setFormState({
        ...formState,
        dlv: false,
        dlv_value: 0,
      })
    }
  }

  //show input DLUO when turn on switch DLUO
  const onChangeDLUO = (value: boolean) => {
    if (value) {
      setShowDLUOnbr(true)
    } else setShowDLUOnbr(false)
  }

  //show input DLV when turn on switch DLV
  const onChangeDLV = (value: boolean) => {
    if (value) {
      setShowDLVnbr(true)
    } else setShowDLVnbr(false)
  }

  //show input DLC when turn on switch DLC
  const onChangeDLC = (value: boolean) => {
    if (value) {
      setShowDLCnbr(true)
    } else setShowDLCnbr(false)
  }

  //update form when input changes
  const onChangeInput = ({ name, value }: NameAndValue) => {
    setFormState({
      ...formState,
      [name || '']: value,
    })
  }

  console.log(
    mode,
    !choosingClient?.variantes && (mode === MODE.CREATE || mode === MODE.EDIT)
  )

  return (
    <SectionLayout title="Gestion">
      <form className="gestion-form">
        <Row className="mt-3">
          <Col xl={{ span: 2 }}>
            <Row className="mb-4">
              <Col xl={{ span: 16 }} className="form-label">
                DLUO
              </Col>
              <Col xl={{ span: 8 }}>
                <CSwitch
                  id={stockKey['DLUO']}
                  nextId={`${stockKey['Nbr-jours-(DLUO)']}, ${stockKey['Gestion-de-lot']}`}
                  previousId={`fournisseuricon-delete-0, ${stockKey['version']} `}
                  name="dluo"
                  onChange={(value) => {
                    onChangeSwitch(value, 'dluo')
                    onChangeDLUO(value)
                  }}
                  checked={formState.dluo}
                  className="custom-switch"
                  disabled={mode === MODE.VIEW}
                />
              </Col>
            </Row>
          </Col>
          <Col xl={{ span: 5, offset: 1 }}>
            {showDLUOnbr && (
              <Row className="mb-4">
                <Col xl={{ span: 12 }} className="form-label">
                  Nbr jours (DLUO)
                </Col>
                <Col xl={{ span: 12 }}>
                  <CInput
                    id={stockKey['Nbr-jours-(DLUO)']}
                    nextId={stockKey['Gestion-de-lot']}
                    previousId={stockKey['DLUO']}
                    type={[InputType.NUMBER_INTEGER_POS]}
                    name="dluo_value"
                    maxLength={3}
                    className="custom-input"
                    format={removeLeadingZero}
                    onChange={onChangeInput}
                    errorMessageForce={
                      gestionError.find((item) => item.name === 'dluo_value')
                        ?.errorMessage
                    }
                    value={formState.dluo_value}
                    disabled={mode === MODE.VIEW}
                  />
                </Col>
              </Row>
            )}
          </Col>
          <Col xl={{ span: 5, offset: 1 }}>
            <Row className="mb-4">
              <Col xl={{ span: 14 }} className="form-label">
                Gestion de lot
              </Col>
              <Col xl={{ span: 10 }}>
                <CSwitch
                  id={stockKey['Gestion-de-lot']}
                  nextId={`${stockKey['Alerte-stock']}, ${stockKey['Variante']}, ${stockKey['DLV']}`}
                  previousId={`${stockKey['Nbr-jours-(DLUO)']}, ${stockKey['DLUO']}`}
                  onChange={(value) => onChangeSwitch(value, 'gestion')}
                  name="gestion"
                  className="custom-switch"
                  checked={formState.gestion}
                  disabled={mode === MODE.VIEW}
                />
              </Col>
            </Row>
          </Col>
          <Col xl={{ span: 5, offset: 1 }}>
            <Row className="mb-4">
              <Col xl={{ span: 12 }} className="form-label">
                Alerte stock
              </Col>
              <Col xl={{ span: 12 }}>
                <CInput
                  id={stockKey['Alerte-stock']}
                  previousId={stockKey['Gestion-de-lot']}
                  nextId={stockKey['Variante']}
                  type={[InputType.NUMBER_INTEGER_POS]}
                  name="alerte_stock"
                  value={formState.alerte_stock}
                  maxLength={10}
                  className="custom-input"
                  disabled={true}
                />
              </Col>
            </Row>
          </Col>
          <Col xl={{ span: 3, offset: 1 }}>
            <Row className="mb-4">
              <Col xl={{ span: 14 }} className="form-label">
                Variante
              </Col>
              <Col xl={{ span: 10 }}>
                <CSwitch
                  id={stockKey['Variante']}
                  previousId={`${stockKey['Alerte-stock']}, ${stockKey['Gestion-de-lot']}`}
                  nextId={stockKey['DLV']}
                  onChange={(value) => onChangeSwitch(value, 'variante')}
                  name="variante"
                  checked={formState.variante}
                  className="custom-switch"
                  disabled={
                    //(!variante && mode === MODE.EDIT) ||
                    mode === MODE.VIEW || !choosingClient?.variantes
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xl={{ span: 2 }}>
            <Row className="mb-4">
              <Col xl={{ span: 16 }} className="form-label">
                DLV
              </Col>
              <Col xl={{ span: 8 }}>
                <CSwitch
                  name="dlv"
                  id={stockKey['DLV']}
                  nextId={`${stockKey['Nbr-jours-(DLV)']}, ${stockKey['Blocage-entrée']}`}
                  previousId={`${stockKey['Variante']}, ${stockKey['Alerte-stock']}, ${stockKey['Gestion-de-lot']}`}
                  onChange={(value) => {
                    onChangeDLV(value)
                    onChangeSwitch(value, 'dlv')
                  }}
                  className="custom-switch"
                  checked={formState.dlv}
                  disabled={mode === MODE.VIEW}
                />
              </Col>
            </Row>
          </Col>
          <Col xl={{ span: 5, offset: 1 }}>
            {showDLVnbr && (
              <Row className="mb-4">
                <Col xl={{ span: 12 }} className="form-label">
                  Nbr jours (DLV)
                </Col>
                <Col xl={{ span: 12 }}>
                  <CInput
                    id={stockKey['Nbr-jours-(DLV)']}
                    nextId={stockKey['Blocage-entrée']}
                    previousId={stockKey['DLV']}
                    type={[InputType.NUMBER_INTEGER_POS]}
                    name="dlv_value"
                    maxLength={3}
                    className="custom-input"
                    format={removeLeadingZero}
                    onChange={onChangeInput}
                    errorMessageForce={
                      gestionError.find((item) => item.name === 'dlv_value')
                        ?.errorMessage
                    }
                    value={formState.dlv_value}
                    disabled={mode === MODE.VIEW}
                  />
                </Col>
              </Row>
            )}
          </Col>
          <Col xl={{ span: 5, offset: 1 }}>
            <Row className="mb-4">
              <Col xl={{ span: 14 }} className="form-label">
                Blocage entrée
              </Col>
              <Col xl={{ span: 10 }}>
                <CSwitch
                  id={stockKey['Blocage-entrée']}
                  nextId={stockKey['DLC']}
                  previousId={`${stockKey['Nbr-jours-(DLV)']}, ${stockKey['DLV']}`}
                  onChange={(value) => onChangeSwitch(value, 'blocage_entree')}
                  name="blocage_entree"
                  className="custom-switch"
                  checked={formState.blocage_entree}
                  disabled={mode === MODE.VIEW}
                />
              </Col>
            </Row>
          </Col>
          <Col xl={{ span: 5, offset: 1 }}>
            <Row className="mb-4">
              <Col xl={{ span: 12 }} className="form-label">
                Niveau alerte
              </Col>
              <Col xl={{ span: 12 }}>
                <CInput
                  id={stockKey['Niveau-alerte']}
                  nextId={stockKey['DLC']}
                  previousId={stockKey['Blocage-entrée']}
                  name="niveau_alerte"
                  value={formState.niveau_alerte}
                  className="custom-input"
                  disabled={true}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xl={{ span: 2 }}>
            <Row className="mb-4">
              <Col xl={{ span: 16 }} className="form-label">
                DLC
              </Col>
              <Col xl={{ span: 8 }}>
                <CSwitch
                  name="dlc"
                  id={stockKey['DLC']}
                  nextId={`${stockKey['Nbr-jours-(DLC)']}, ${stockKey['Blocage-sortie']}`}
                  previousId={stockKey['Blocage-entrée']}
                  onChange={(value) => {
                    onChangeSwitch(value, 'dlc')
                    onChangeDLC(value)
                  }}
                  className="custom-switch"
                  checked={formState.dlc}
                  disabled={mode === MODE.VIEW}
                />
              </Col>
            </Row>
          </Col>
          <Col xl={{ span: 5, offset: 1 }}>
            {showDLCnbr && (
              <Row className="mb-4">
                <Col xl={{ span: 12 }} className="form-label">
                  Nbr jours (DLC)
                </Col>
                <Col xl={{ span: 12 }}>
                  <CInput
                    type={[InputType.NUMBER_INTEGER_POS]}
                    name="dlc_value"
                    id={stockKey['Nbr-jours-(DLC)']}
                    nextId={stockKey['Blocage-sortie']}
                    previousId={stockKey['DLC']}
                    maxLength={3}
                    className="custom-input"
                    format={removeLeadingZero}
                    onChange={onChangeInput}
                    errorMessageForce={
                      gestionError.find((item) => item.name === 'dlc_value')
                        ?.errorMessage
                    }
                    value={formState.dlc_value}
                    disabled={mode === MODE.VIEW}
                  />
                </Col>
              </Row>
            )}
          </Col>
          <Col xl={{ span: 5, offset: 1 }}>
            <Row className="mb-4">
              <Col xl={{ span: 14 }} className="form-label">
                Blocage sortie
              </Col>
              <Col xl={{ span: 10 }}>
                <CSwitch
                  onChange={(value) => onChangeSwitch(value, 'blocage_sortie')}
                  name="blocage_sortie"
                  id={stockKey['Blocage-sortie']}
                  nextId={`${stockKey['Chargement']}, ${stockKey['Supprimer']}, ${stockKey['Sauvegarder']}`}
                  previousId={`${stockKey['Nbr-jours-(DLC)']}, ${stockKey['DLC']}`}
                  className="custom-switch"
                  checked={formState.blocage_sortie}
                  disabled={mode === MODE.VIEW}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    </SectionLayout>
  )
}

export default Gestion
