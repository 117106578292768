import _ from 'lodash'
import referenceApi from '../../../http/referenceApi'
import { validateHasSameLastDigit_8_13_14 } from '../../../utils'
import { DataTableParam, ForceShowWarning } from './component/model'
import Define from '../../../constants/define'

export function getValueFromLine(name: string, line: DataTableParam[]) {
  return line.find((item) => item.name === name)?.value
}

export function getValuesFromLine(names: string[], line: DataTableParam[]) {
  const result: (string | undefined)[] = []

  names.forEach((name) => result.push(getValueFromLine(name, line)))

  return result
}

/**
 *
 * @param payload ean, variante
 * @returns if other reference already have this EAN-variante
 */
export async function checkDuplicateEANVarianteThroughAPI(payload: {
  ean: string
  variante: string
  id: string
}) {
  try {
    await referenceApi.checkDuplicateEANVariante({
      ean: payload.ean,
      variante: payload.variante,
      id: payload.id,
    })
    return true
  } catch (e: any) {
    if (e.response.data.error.includes('record not found')) return false
    else throw e
  }
}

/**
 *
 * @param data all lines of table
 * @param index index of current line
 * @returns if other reference or inside table already have this EAN-variante
 */
export async function checkDuplicateEANVariante(
  data: DataTableParam[][],
  index: number
) {
  //filter all lines except current line
  const dataCloneFilter = data.filter((i, idx) => idx !== index)
  let isDuplicateEANVariante = false

  const currentEditLine = data[index]

  const [currentEditVariante, currentEditCodeBarre, currentEditId] =
    getValuesFromLine(['variante', 'code_barre', 'id'], currentEditLine)

  //if table has 1 line: check in DB if already have this EAN - variante
  if (data.length === 1) {
    isDuplicateEANVariante = await checkDuplicateEANVarianteThroughAPI({
      ean: currentEditCodeBarre || '',
      variante: currentEditVariante || '',
      id: currentEditId || '',
    })
  } else {
    //if table has more than 1 line: find a line has type EAN, same code barre, same variante with current line
    const existedEANVarianteLines = dataCloneFilter.filter(
      (item) =>
        item.find((i) => i.name === 'variante')?.value ===
          currentEditVariante &&
        item.find((i) => i.name === 'code_barre')?.value ===
          currentEditCodeBarre &&
        item.find((i) => i.name === 'typeCB')?.value === 'EAN'
    )

    //if that line exist: table has 2 lines duplicated
    if (existedEANVarianteLines.length > 0) {
      isDuplicateEANVariante = true
    } else {
      //if that line not exist: check in DB if already have this EAN - variante
      isDuplicateEANVariante = await checkDuplicateEANVarianteThroughAPI({
        ean: currentEditCodeBarre || '',
        variante: currentEditVariante || '',
        id: currentEditId || '',
      })
    }
  }

  return { isDuplicateEANVariante }
}

/**
 *
 * @param data all lines of table
 * @returns array of indexes of duplicated lines
 */
export async function checkDuplicateEANVarianteAllLines(
  data: DataTableParam[][]
) {
  let arrIndexesOfDuplicatedLines: number[] = []

  // if table has 1 line: call api check duplicate
  if (data.length === 1) {
    const [
      currentEditVariante,
      currentEditTypeCB,
      currentEditCodeBarre,
      currentEditId,
    ] = getValuesFromLine(['variante', 'typeCB', 'code_barre', 'id'], data[0])

    if (currentEditTypeCB === 'EAN')
      try {
        let isDuplicateEANVariante = await checkDuplicateEANVarianteThroughAPI({
          ean: currentEditCodeBarre || '',
          variante: currentEditVariante || '',
          id: currentEditId || '',
        })
        if (isDuplicateEANVariante) {
          arrIndexesOfDuplicatedLines.push(0)
        }
      } catch (e) {}
  } else {
    //check duplicate in UI
    for (let i = 0; i < data.length; i++) {
      if (i === data.length) continue

      const [currentEditVariante, currentEditCodeBarre, currentEditTypeCB] =
        getValuesFromLine(['variante', 'code_barre', 'typeCB'], data[i])

      for (let j = i + 1; j < data.length; j++) {
        const [nextEditVariante, nextEditCodeBarre, nextEditTypeCB] =
          getValuesFromLine(['variante', 'code_barre', 'typeCB'], data[j])

        if (
          currentEditTypeCB === 'EAN' &&
          nextEditTypeCB === 'EAN' &&
          currentEditCodeBarre === nextEditCodeBarre &&
          currentEditVariante === nextEditVariante
        ) {
          arrIndexesOfDuplicatedLines.push(i, j)
        }
      }
    }
    //filter not duplicated lines
    const arrOfNotDuplicateLines = data.filter(
      (item, idx) =>
        !arrIndexesOfDuplicatedLines.includes(idx) &&
        item.find((i) => i.value === 'EAN')
    )

    //check duplicate through not duplicated lines by api
    try {
      const arrIndexesOfNotDuplicateLines = await Promise.all(
        arrOfNotDuplicateLines.map((item) => {
          return checkDuplicateEANVarianteThroughAPI({
            ean: item.find((i) => i.name === 'code_barre')?.value || '',
            variante: item.find((i) => i.name === 'variante')?.value || '',
            id: item.find((i) => i.name === 'id')?.value || '',
          })
        })
      )

      for (let i = 0; i < arrIndexesOfNotDuplicateLines.length; i++) {
        if (!arrIndexesOfNotDuplicateLines[i]) continue

        arrIndexesOfDuplicatedLines.push(
          data.findIndex((item) =>
            _.isEqual(item.sort(), arrOfNotDuplicateLines[i].sort())
          )
        )
      }
    } catch (e) {}
  }

  return { arrIndexesOfDuplicatedLines }
}

/**
 *
 * @param line current line want to check valid
 * @returns
 */
function lineIsValid(line: DataTableParam[]) {
  const [currentEditVariante, currentEditCodeBarre, currentEditTypeCB] =
    getValuesFromLine(['variante', 'code_barre', 'typeCB'], line)

  //if current line has type CB is Autre or current line has type CB is EAN, has variante and valid code barre
  if (
    (currentEditVariante &&
      currentEditCodeBarre &&
      currentEditTypeCB === 'EAN' &&
      validateHasSameLastDigit_8_13_14(currentEditCodeBarre)) ||
    currentEditTypeCB === 'Autre'
  ) {
    return true
  }
  return false
}

export function allLinesAreValid(lines: DataTableParam[][]) {
  return lines.every((line) => lineIsValid(line))
}

/**
 *
 * @param payload
 * @returns variante warning message
 */
export async function showWarningCheckDuplicateEANVariante(payload: {
  data: DataTableParam[][]
  index: number
  forceShowWarningClone: ForceShowWarning[][]
  name: string
  message: string
  enableVariante: boolean
}) {
  const { data, index, forceShowWarningClone, name, message, enableVariante } =
    payload

  const [currentEditVariante, currentEditTypeCB, currentEditCodeBarre] =
    getValuesFromLine(['variante', 'typeCB', 'code_barre'], data[index])
  if (
    currentEditTypeCB === 'EAN' &&
    enableVariante &&
    currentEditVariante &&
    currentEditCodeBarre &&
    currentEditVariante.length === 2 &&
    validateHasSameLastDigit_8_13_14(currentEditCodeBarre)
  ) {
    try {
      const { isDuplicateEANVariante } = await checkDuplicateEANVariante(
        data,
        index
      )
      if (isDuplicateEANVariante) {
        forceShowWarningClone[index].push({
          name,
          message,
        })
      } else {
        forceShowWarningClone[index] = forceShowWarningClone[index].filter(
          (item) => item.name !== name
        )
      }
    } catch (e) {}
  }
  return forceShowWarningClone
}

export function checkIfClientWarehouseCompanyCorrespond(result: any) {
  // if 3 warehouse information are different from 3 warehouse chosen from search bar then navigate to dashboard
  return !(
    result.client_code_nom !==
      localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM) ||
    result.warehouse_code_nom !==
      localStorage.getItem(Define.CHOOSING_WAREHOUSE_CODENOM) ||
    result.company_code_nom !==
      localStorage.getItem(Define.CHOOSING_COMPANY_CODENOM)
  )
}
