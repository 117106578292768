import {
  FilePaseInterface,
  FileResponseCmdInterface,
  FileResponseInterface,
  SingleFileResponseInterface,
} from './../models'
import baseAxios from './clientAPI'

const fileApi = {
  uploadMultiFiles(data: any): Promise<FileResponseInterface> {
    const url = '/files/attendu/upload-multiple-file'

    return baseAxios.post(url, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },

  parseDownloadFile(filePath: string): Promise<FilePaseInterface> {
    const url = `/warehouse/attendu/file/url/${filePath}`
    return baseAxios.get(url)
  },

  downloadFile(filePath: string) {
    const url = `/warehouse/attendu/file/url-download/${filePath}`
    return baseAxios.get(url)
  },

  deleteFile(filePath?: string) {
    const url = `/files/attendu/${filePath}`
    return baseAxios.delete(url)
  },

  uploadCommonFile(
    type: string,
    file: any
  ): Promise<SingleFileResponseInterface> {
    const url = `/files/common/${type}`
    return baseAxios.post(url, file, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },

  uploadMultiCommonFiles(payload: {
    type: string
    file: any
  }): Promise<FileResponseCmdInterface> {
    const url = `files/common/multi/${payload.type}`
    return baseAxios.post(url, payload.file, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },
}

export default fileApi
