import { Popconfirm, Spin, notification } from 'antd'
import { cloneDeep, isEmpty, uniqBy } from 'lodash'
import { ReactNode, useEffect, useState } from 'react'
import CLOSE_ICON from '../../../../../assets/images/close-success.png'
import ERROR from '../../../../../assets/images/error.png'
import { InputType } from '../../../../../enum/InputType'
import { MODE } from '../../../../../enum/mode'
import fournisseurApi from '../../../../../http/fournisserApi'
import referenceApi from '../../../../../http/referenceApi'
import { removeTonesUpperCase } from '../../../../../utils'
import firstLine from '../../data/initFournisseur.data.json'
import ITable from '../ITable'
import SectionLayout from '../SectionLayout'
import { InvalidProperty, NameAndValue } from '../Wrapper'
import {
  ActionParam,
  DataTableParam,
  ForceShowError,
  FormatRowCol,
  OptionType,
  TitleParam,
} from '../model'
import './Fournisseurs.scss'

import { ActionOnReference } from '../../../../../enum/ActionOnReference'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../app/store'
import { focusById } from '../../../../../hook/usePressTab'
import Define from '../../../../../constants/define'

interface ActionProps {
  isDisablePlusBtn?: boolean

  onClickPlusBtn?(): void
}

interface FournisseursProps {
  /**
   * update data of fournisseurs when table data changed
   * @param value changed data of table
   * @returns void
   */
  onChangeData?: (value: DataTableParam[][]) => void
  /**
   * check valid of table when onclick sauvegader
   * @param value is valid
   * @returns void
   */
  onCheckValid?: (value: boolean) => void
  /**
   * alert if have errors
   */
  alert?: ReactNode
  /**
   * init fournisseur data
   */
  initData?: DataTableParam[][]
  /**
   * mode
   */
  mode: MODE
  /**
   *
   * @param id
   * @returns fournisseur id
   */
  onDeleteLine?: (id: string) => void
  /**
   * client code nom of fournisseur
   */
  clientCodeNom?: string

  action?: ActionOnReference
  /**
   * length of detail ref array
   */
  detailLength: number
}

const formatCols: FormatRowCol[] = [
  {
    name: 'fournisseur',
    format: removeTonesUpperCase,
  },
]

const Action = (props: ActionProps) => {
  const onClickPlusBtn = (): void => {
    if (props?.isDisablePlusBtn) return
    if (props?.onClickPlusBtn) props.onClickPlusBtn()
  }

  return (
    <div className="flex items-center gap-3">
      <i
        className={`${
          props?.isDisablePlusBtn ? 'icon-add-plus-disable' : 'icon-add-plus'
        }`}
        onClick={onClickPlusBtn}
      ></i>
    </div>
  )
}

/**
 * The @Fournisseurs is a component display table of fournisseurs in modal reference
 * @author [anhnq]
 * @version 0.1
 */

const defaultPromise: Promise<OptionType[]> = Promise.resolve([])

const Fournisseurs = (props: FournisseursProps) => {
  const stockKey = useSelector((state: RootState) => state.selector).data.stock

  const firstLineData = JSON.parse(
    JSON.stringify(firstLine)
  ) as DataTableParam[]

  const initData: DataTableParam[][] = [firstLineData]
  const cInputProps = {
    className: 'border-children-cell-table text-center',
    requiredMessage: '',
    validates: [],
  }

  const [listNotFillRequired, setListNotFillRequired] = useState<string[][]>([
    [],
  ])
  const [actions, setActions] = useState<ActionParam[]>([])
  const [forceShowError, setForceShowError] = useState<ForceShowError[][]>([[]])
  const [disableLines, setDisableLines] = useState<number[]>([])

  const [formatRows, setFormatRows] = useState<FormatRowCol[][]>([
    [
      {
        name: 'fournisseur',
        format: removeTonesUpperCase,
      },
    ],
  ])

  const [resetFlags, setResetFlags] = useState<boolean[]>([])

  const [header, setHeader] = useState<TitleParam[]>([
    {
      label: 'Nom',
      required: true,
      name: 'nom',
      cInputProps: {
        ...cInputProps,
        type: [InputType.C_SEARCH],
        className: 'border-children-cell-table text-center w-25p',
        cellClass: 'py-3',
      },
    },
    {
      label: 'Référence fournisseur',
      required: false,
      name: 'fournisseur',
      cInputProps: {
        ...cInputProps,
        maxLength: 20,
        placeHolder: '-',
        className: 'border-children-cell-table text-center w-full',
      },
    },
  ])

  const [data, setData] = useState<DataTableParam[][]>(initData)
  const [invalidList, setInvalidList] = useState<InvalidProperty[][]>([[]])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  useEffect(() => {
    const headerClone = cloneDeep(header)
    const nomIndex = headerClone.findIndex((item) => item.name === 'nom')
    headerClone[nomIndex].cInputProps.onSearch = (keyword: string) => {
      if (keyword.length >= 1) {
        return fournisseurApi
          .searchFournisseurByNameOrCode({
            data: keyword,
            client_code_nom:
              props.mode === MODE.EDIT
                ? props.clientCodeNom || ''
                : localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM) || '',
          })
          .then((res) => res.data.entry || [])
          .then((res) =>
            res.map((item) => {
              let option: OptionType = {
                label: `${item.code}_${item.nom}`,
                value: `${item.code}_${item.nom}`,
              }
              return option
            })
          )
      }

      return defaultPromise
    }
    setHeader(headerClone)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clientCodeNom])

  useEffect(() => {
    if (!props.initData) return
    if (props.initData.length === 0) setData(initData)
    else setData(props.initData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initData])

  useEffect(() => {
    const actionsClone = cloneDeep(actions) as ActionParam[]

    for (let i = 0; i < listNotFillRequired.length; i++) {
      if (isEmpty(listNotFillRequired[i])) {
        actionsClone[i] = {
          ...actionsClone[i],
          isFillAllRequired: true,
        }
      } else {
        actionsClone[i] = {
          ...actionsClone[i],
          isFillAllRequired: false,
          isValid: false,
        }
      }
    }
    for (let i = 0; i < invalidList.length; i++) {
      if (isEmpty(invalidList[i]))
        actionsClone[i] = {
          ...actionsClone[i],
          isValid: true,
        }
      else
        actionsClone[i] = {
          ...actionsClone[i],
          isValid: false,
        }
    }

    setActions(actionsClone)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listNotFillRequired, invalidList])

  useEffect(() => {
    if (props?.initData) {
      const actionsClone = cloneDeep(actions)
      const initLength = props.initData.length
      for (let i = 0; i < initLength; i++) {
        actionsClone[i] = {
          isAllowDelete: true,
          isAllowEdit: true,
          isFillAllRequired: true,
          isForceValid: true,
          isValid: true,
        }
      }
      setActions(actionsClone)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.initData])

  const calcNewLine = (oldLine: DataTableParam[]): DataTableParam[] => {
    let newData = cloneDeep(initData[0])

    return newData
  }

  const onAddNewLine = () => {
    const dataClone = cloneDeep(data)
    const formatRowsClone = cloneDeep(formatRows)
    const actionClone = cloneDeep(actions)
    const forceShowErrorClone = cloneDeep(forceShowError)

    const newData = calcNewLine(dataClone[dataClone.length - 1])

    dataClone.push(newData)

    for (let i = 0; i < actionClone.length; i++) {
      actionClone[i].isAllowEdit = false
      actionClone[i].isAllowDelete = false
    }

    actionClone.push({
      isAllowDelete: true,
      isFillAllRequired: false,
      isForceValid: false,
      isValid: false,
      isAllowEdit: true,
    })

    forceShowErrorClone.push([])
    setData(dataClone)
    setFormatRows(formatRowsClone)
    setActions(actionClone)
    setForceShowError(forceShowErrorClone)
    focusById(`fournisseurnom-${actionClone.length - 1}`, 500)
  }

  const onCheckFillRequired = (index: number, list: string[]) => {
    const isUseQteVariable = false
    const listNotFillRequiredClone = cloneDeep(listNotFillRequired)
    listNotFillRequiredClone[index] = list
    if (isUseQteVariable) {
      for (let i = 0; i < listNotFillRequiredClone.length; i++) {
        listNotFillRequiredClone[i] = listNotFillRequiredClone[i].filter(
          (item) => item !== 'qte_contenant'
        )
      }
    }
    setListNotFillRequired(listNotFillRequiredClone)
  }

  const onDeleteLine = (index: number) => {
    if (props.mode === MODE.EDIT) {
      const id = data[index].find((item) => item.name === 'id')?.value

      if (id) {
        setIsLoading(true)
        referenceApi
          .checkRefFnsAllowDeleteOrNot(id)
          .then((res) => {
            if (res.data.status) {
              deleteLine(index)
            } else {
              notification.open({
                className: 'noti noti-error',
                message: (
                  <div className="flex items-center">
                    <img
                      src={ERROR}
                      alt="error"
                      width={50}
                      className="mr-2.5"
                    />
                    Suppression impossible:
                    <br />
                    Élément existant à une opération
                  </div>
                ),
                placement: 'topRight',
                closeIcon: (
                  <img src={CLOSE_ICON} alt="close" className="ml-28" />
                ),
                duration: 3,
              })
            }
          })
          .catch((err) => {})
          .finally(() => {
            setIsLoading(false)
          })
      } else deleteLine(index)
    } else deleteLine(index)
  }

  const deleteLine = (index: number) => {
    const dataClone = cloneDeep(data)
    const forceShowErrorClone = cloneDeep(forceShowError)
    const resetFlagClone = cloneDeep(resetFlags)
    let formatRowsClone = cloneDeep(formatRows)
    let actionsClone = cloneDeep(actions)
    let disabledLinesClone = cloneDeep(disableLines)
    let invalidListClone = cloneDeep(invalidList)
    let listNotFillRequiredClone = cloneDeep(listNotFillRequired)
    const id = dataClone[index].find((item) => item.name === 'id')?.value

    if (data.length === 1 && index === 0) {
      dataClone[0] = firstLineData
      resetFlagClone[0] = !resetFlagClone[0]
      actionsClone[0] = {
        isAllowDelete: true,
        isFillAllRequired: false,
        isForceValid: false,
        isValid: false,
        isAllowEdit: true,
      }
    } else {
      dataClone.splice(index, 1)
      actionsClone.splice(index, 1)

      for (let i = 0; i < actionsClone.length; i++) {
        actionsClone[i].isAllowEdit = true
        actionsClone[i].isAllowDelete = true
      }

      forceShowErrorClone.splice(index, 1)
      formatRowsClone.splice(index, 1)
      invalidListClone.splice(index, 1)
      listNotFillRequiredClone.splice(index, 1)
    }
    forceShowErrorClone[index] = []
    disabledLinesClone = disabledLinesClone.filter((item) => item !== index)
    disabledLinesClone = disabledLinesClone.map((item) =>
      item > index ? item - 1 : item
    )

    if (props?.onDeleteLine) props.onDeleteLine(id || '')
    if (props?.onChangeData) props.onChangeData(dataClone)

    setData(dataClone)
    setForceShowError(forceShowErrorClone)
    setResetFlags(resetFlagClone)
    setDisableLines(disabledLinesClone)
    setActions(actionsClone)
    setFormatRows(formatRowsClone)
    setInvalidList(invalidListClone)
    setListNotFillRequired(listNotFillRequiredClone)
  }

  const onCheckValidByBtn = async (index: number) => {
    const forceShowErrorClone = cloneDeep(forceShowError)
    const actionsClone = cloneDeep(actions)

    if (data.length >= 1) {
      let skipCheckExist = false
      const currentEditLine = data[index]
      const currentEditUuid = currentEditLine.find(
        (item) => item.name === 'uuid'
      )?.value
      const currentEditNom = currentEditLine.find(
        (item) => item.name === 'nom'
      )?.value
      const currentEditFournisseur = currentEditLine.find(
        (item) => item.name === 'fournisseur'
      )?.value

      if (props?.initData) {
        for (let i = 0; i < props?.initData.length || 0; i++) {
          const currentLine = props.initData[i]
          const currentUuid = currentLine.find(
            (item) => item.name === 'uuid'
          )?.value
          if (currentUuid === currentEditUuid) {
            const currentNom = currentLine.find(
              (item) => item.name === 'nom'
            )?.value
            const currentFns = currentLine.find(
              (item) => item.name === 'fournisseur'
            )?.value

            if (
              currentFns === currentEditFournisseur &&
              currentNom === currentEditNom
            ) {
              skipCheckExist = true
              break
            }
          }
        }
      }

      if (!skipCheckExist && currentEditFournisseur) {
        try {
          await referenceApi.checkFournisseurExisted({
            client_code_nom:
              props.clientCodeNom ||
              localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM) ||
              '',
            fournisser_id: currentEditNom || '',
            reference_fournisser: currentEditFournisseur || '',
          })

          forceShowErrorClone[index].push({
            name: 'fournisseur',
            message: 'Numéro déjà existant',
          })
        } catch (error) {
          forceShowErrorClone[index] = forceShowError[index].filter(
            (item) => item.name !== 'fournisseur'
          )
          setForceShowError(forceShowErrorClone)
        }
      }

      let i = 0
      for (i = 0; i < data.length; i++) {
        if (i === index) continue
        const currentLine = data[i]
        const currentNom = currentLine.find(
          (item) => item.name === 'nom'
        )?.value
        if (currentEditNom !== currentNom) continue

        forceShowErrorClone[index] = forceShowErrorClone[index].filter(
          (item) => item.name !== 'nom'
        )

        forceShowErrorClone[index].push({
          name: 'nom',
          message: 'Fournisseur déjà existant',
        })

        break
      }
      if (i === data.length)
        forceShowErrorClone[index] = forceShowErrorClone[index].filter(
          (item) => item.name !== 'nom'
        )
    }

    let disableLinesClone = cloneDeep(disableLines)
    if (isEmpty(forceShowErrorClone[index])) {
      actionsClone[index].isForceValid = true
      for (let i = 0; i < actionsClone.length; i++) {
        actionsClone[i].isAllowEdit = true
        actionsClone[i].isAllowDelete = true
      }
      disableLinesClone.push(index)
      disableLinesClone = uniqBy(disableLinesClone, (e) => e)
      focusById(`fournisseuricon-edit-${actionsClone.length - 1}`)
    }
    //  debugger
    setForceShowError(forceShowErrorClone)
    setActions(actionsClone)
    setDisableLines(disableLinesClone)
  }

  const onAllowEdit = async (index: number) => {
    let disableLinesClone = cloneDeep(disableLines)
    let actionsClone = cloneDeep(actions)
    const dataClone = cloneDeep(data)
    disableLinesClone = disableLinesClone.filter((item) => item !== index)
    actionsClone[index].isForceValid = false
    actionsClone[index].isAllowEdit = true
    for (let i = 0; i < actionsClone.length; i++) {
      if (i !== index) {
        actionsClone[i].isAllowEdit = false
        focusById(`fournisseuricon-valid-${actionsClone.length - 1}`, 500)
      }
    }

    //disabled ref if it used to in at least an operation;

    let usedAtLeastInAnOperation = false
    const currentId = dataClone[index].find((item) => item.name === 'id')?.value
    if (currentId) {
      try {
        setIsLoading(true)
        const result = await referenceApi.checkRefFnsAllowDeleteOrNot(currentId)
        if (result.data.status) usedAtLeastInAnOperation = false
        else usedAtLeastInAnOperation = true
      } catch (error) {
      } finally {
        setIsLoading(false)
      }
    }

    const currentLine = dataClone[index]
    const indexOfRef = currentLine.findIndex((item) => item.name === 'nom')
    if (usedAtLeastInAnOperation) dataClone[index][indexOfRef].disabled = true
    else dataClone[index][indexOfRef].disabled = false

    setActions(actionsClone)
    setDisableLines(disableLinesClone)
    setData(dataClone)
  }

  const onCheckInvalid = (
    index: number,
    invalidProperty: InvalidProperty | null
  ) => {
    const inValidListClone = cloneDeep(invalidList)
    if (!invalidProperty) inValidListClone[index] = []
    else {
      const indexOfProperty = inValidListClone[index].findIndex(
        (item) => item.name === invalidProperty?.name
      )
      if (indexOfProperty === -1) inValidListClone[index].push(invalidProperty)
      else inValidListClone[index][indexOfProperty] = invalidProperty
    }

    setInvalidList(inValidListClone)
  }

  const onChangeDataTable = (index: number, nameAndValue: NameAndValue) => {
    const dataClone = cloneDeep(data)
    const forceShowErrorClone = cloneDeep(forceShowError)
    let currentLine = dataClone[index]

    let currentChange = currentLine.find(
      (item) => item.name === nameAndValue.name
    )
    if (!currentChange) return

    currentChange.value = nameAndValue.value

    currentLine = currentLine.filter((item) => item.name !== nameAndValue.name)
    currentLine.push(currentChange)

    if (currentChange.name === 'nom') {
      forceShowErrorClone[index] = forceShowErrorClone[index].filter(
        (item) => item.message !== 'Fournisseur déjà existant'
      )
    }

    if (currentChange.name === 'fournisseur') {
      forceShowErrorClone[index] = forceShowErrorClone[index].filter(
        (item) => item.message !== 'Numéro déjà existant'
      )
    }

    setForceShowError(forceShowErrorClone)
    dataClone[index] = currentLine
    if (props?.onChangeData) props.onChangeData(dataClone)
    setData(dataClone)
  }

  useEffect(() => {
    if (!props?.onCheckValid) return
    //check is valid fournisseurs table
    let isAllValid = true
    let findFirstNom = data[0]?.find((item) => item.name === 'nom')?.value

    if (findFirstNom) {
      isAllValid =
        findFirstNom.length > 0 &&
        actions.every((item) => item.isAllowEdit && item.isForceValid)
    }

    props.onCheckValid(isAllValid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions, data])

  useEffect(() => {
    if (!props?.initData) return
    const forceShowErrorClone = []
    const disabledLinesClone = []
    const listNotFillRequiredClone = []
    if (props.initData.length === 0) forceShowErrorClone.push([])
    for (let i = 0; i < props.initData.length; i++) {
      forceShowErrorClone.push([])
      disabledLinesClone.push(i)
      listNotFillRequiredClone.push([])
    }
    setForceShowError(forceShowErrorClone)
    setDisableLines(disabledLinesClone)
    setListNotFillRequired(listNotFillRequiredClone)
  }, [props?.initData])

  return (
    <SectionLayout
      title="Fournisseurs"
      action={
        <Action
          isDisablePlusBtn={
            props.mode === MODE.VIEW ||
            !(
              actions[actions.length - 1]?.isForceValid &&
              actions[actions.length - 1]?.isValid
            ) ||
            data.length >= 99 ||
            disableLines.length !== data.length
          }
          onClickPlusBtn={onAddNewLine}
        />
      }
      alert={props?.alert}
    >
      <Spin spinning={isLoading}>
        <ITable
          resetFlags={resetFlags}
          formatCols={formatCols}
          formatRows={formatRows}
          header={header}
          data={data}
          actions={props.mode !== MODE.VIEW ? actions : []}
          disabledLines={disableLines}
          onChangeDataTable={onChangeDataTable}
          onCheckInvalid={onCheckInvalid}
          onCheckFillRequired={onCheckFillRequired}
          forceShowError={forceShowError}
          hideCols={[]}
          forceReset={[]}
          name="fournisseur"
          previousId={`conditionementsicon-delete-${
            props.detailLength > 0 ? props.detailLength - 1 : 0
          }`}
          actionNode={(index: number, actionParam?: ActionParam): ReactNode => {
            if (props.mode === MODE.VIEW) return false
            const {
              isValid = false,
              isFillAllRequired = false,
              isAllowDelete = true,
              isForceValid = false,
              isAllowEdit = true,
            } = actionParam || {
              isValid: false,
              isFillAllRequired: false,
              isAllowDelete: true,
              isForceValid: false,
              isAllowEdit: true,
            }

            return (
              <>
                <div className="flex justify-center align-middle">
                  <div className="mr-2">
                    {(!isFillAllRequired || !isValid) && (
                      <i className="icon-valid-disable"></i>
                    )}
                    {isFillAllRequired && isValid && !isForceValid && (
                      <i
                        onClick={() => onCheckValidByBtn(index)}
                        className="icon-valid focus:rounded-2xl"
                        data-previous-id={`fournisseurfournisseur-${index}`}
                        data-next-id={`fournisseuricon-delete-${index}`}
                        id={`fournisseuricon-valid-${index}`}
                        tabIndex={0}
                      />
                    )}
                    {isFillAllRequired &&
                      isValid &&
                      isForceValid &&
                      isAllowEdit && (
                        <i
                          className="icon-edit"
                          id={`fournisseuricon-edit-${index}`}
                          data-previous-id={`fournisseurfournisseur-${index}`}
                          data-next-id={`fournisseuricon-delete-${index}`}
                          tabIndex={0}
                          onClick={() => onAllowEdit(index)}
                        />
                      )}
                    {isFillAllRequired &&
                      isValid &&
                      isForceValid &&
                      !isAllowEdit && <i className="icon-edit-disable" />}
                  </div>

                  {isAllowDelete && (
                    <Popconfirm
                      icon={null}
                      title="Êtes-vous sûr de vouloir supprimer cet élément?"
                      description=""
                      okText="Supprimer"
                      okButtonProps={{ className: 'ok-btn' }}
                      cancelButtonProps={{ className: 'cancel-btn' }}
                      cancelText="Annuler"
                      onConfirm={() => onDeleteLine(index)}
                      onCancel={() =>
                        focusById(`fournisseuricon-delete-${index}`)
                      }
                    >
                      <i
                        className="icon-delete focus:rounded-2xl"
                        tabIndex={0}
                        id={`fournisseuricon-delete-${index}`}
                        data-previous-id={`fournisseuricon-valid-${index}, fournisseuricon-edit-${index}, fournisseurfournisseur-${index}`}
                        data-next-id={`fournisseuricon-edit-${index + 1}, ${
                          stockKey['DLUO']
                        }, ${stockKey['Chargement']}, ${
                          stockKey['Supprimer']
                        }, ${stockKey['Sauvegarder']}`}
                      ></i>
                    </Popconfirm>
                  )}
                  {!isAllowDelete && <i className="icon-delete-disable"></i>}
                </div>
              </>
            )
          }}
        ></ITable>
      </Spin>
    </SectionLayout>
  )
}

export default Fournisseurs
