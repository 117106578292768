import { Col, notification, Row, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../app/store'
import BTN_DELETE from '../../../../../assets/icons/btn-delete.svg'
import BTN_UPLOAD from '../../../../../assets/icons/btn-upload.svg'
import CLOSE_ICON from '../../../../../assets/images/close-success.png'
import ERROR from '../../../../../assets/images/error.png'
import DEFAULT_IMAGE from '../../../../../assets/images/masques.svg'
import AutocompletePays from '../../../../../components/Common/AutoCompletePays'
import { InputType } from '../../../../../enum/InputType'
import { MODE } from '../../../../../enum/mode'
import fileApi from '../../../../../http/fileApi'
import { CommonError } from '../../../../../models/common/error'
import { removeTones, removeTonesUpperCase } from '../../../../../utils'
import SectionLayout from '../SectionLayout'
import { CInput, NameAndValue } from '../Wrapper'
import './Identification.scss'
import { ActionOnReference } from '../../../../../enum/ActionOnReference'
import { focusById } from '../../../../../hook/usePressTab'
import { useTranslation } from 'react-i18next'
import Define from '../../../../../constants/define'

//identification state
export interface FormStateInterface {
  number: string
  libelle_long: string
  marque: string
  libelle_court: string
  langua_libelle: string
  origine: string
  sous_famille: string
  famille: string
  photo: string
  client?: string
}

interface IdentificationProps {
  /**
   * update data of fournisseurs when table data changed
   * @param value changed data of table
   * @returns void
   */
  onChangeData?: (newValue: FormStateInterface) => void
  /**
   * check valid of table when onclick sauvegader
   * @param value is valid
   * @returns void
   */
  onCheckValid?: (isValid: boolean) => void
  /**
   * array of identification errors
   */
  identificationError?: CommonError[]
  /**
   * init identification data
   */
  initData?: FormStateInterface
  /**
   * mode
   */
  mode: MODE

  action?: ActionOnReference
}

//list options of Langue libellé
const optionsLanguage = [
  {
    value: 'fr',
    label: 'FR',
  },
  {
    value: 'en',
    label: 'EN',
  },
  {
    value: 'de',
    label: 'DE',
  },
  {
    value: 'es',
    label: 'ES',
  },
  {
    value: 'pt',
    label: 'PT',
  },
  {
    value: 'du',
    label: 'DU',
  },
  {
    value: 'it',
    label: 'IT',
  },
]

/**
 * The @Identification is a component display Identification in modal reference
 * @author [anhnq]
 * @version 0.1
 */
const Identification = (props: IdentificationProps) => {
  const { t } = useTranslation()
  const stockKey = useSelector((state: RootState) => state.selector).data.stock
  const baseURL = process.env.REACT_APP_API_ENDPOINT
  const {
    onChangeData = () => {},
    onCheckValid = () => {},
    identificationError = [],
    initData,
    mode,
  } = props

  const [formState, setFormState] = useState<FormStateInterface>({
    number: '',
    libelle_long: '',
    marque: '',
    libelle_court: '',
    langua_libelle: '',
    origine: '',
    sous_famille: '',
    famille: '',
    photo: '',
  })
  const [isValidNumber, setIsValidNumber] = useState<boolean>(false)
  const [isValidLibelleLong, setIsValidLibelleLong] = useState<boolean>(false)
  const [photoUploadMode, setPhotoUploadMode] = useState<boolean>(false)
  const [loadingUploadPhoto, setLoadingUploadPhoto] = useState<boolean>(false)
  //update data of identification when a field changes
  useEffect(() => {
    onChangeData(formState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState])

  useEffect(() => {
    if (!initData) return
    setFormState(initData)
  }, [initData])

  useEffect(() => {
    if (formState.photo) {
      setPhotoUploadMode(true)
    }
  }, [formState.photo])

  //update valid status
  useEffect(() => {
    setIsValidNumber(!!formState.number)
    setIsValidLibelleLong(!!formState.libelle_long)
    onCheckValid(isValidNumber && isValidLibelleLong)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, isValidNumber, isValidLibelleLong])

  //update form when select changes
  const onChangeSelect = ({ name, value }: { name: string; value: string }) => {
    setFormState({
      ...formState,
      [name]: value,
    })
  }

  //update form when autocomplete changes
  const onUpdateAutoComplete = ({
    name,
    selectedValue,
  }: {
    name: string
    selectedValue: string
  }) => {
    setFormState({
      ...formState,
      [name]: selectedValue,
    })
  }

  const onChangeAutoComplete = (value: string) => {
    setFormState({
      ...formState,
      origine: value,
    })
  }

  //update form when input changes
  const onChangeInput = ({ name, value }: NameAndValue) => {
    setFormState({
      ...formState,
      [name || '']: value,
    })
  }

  //function upload ref photo
  const uploadRefPhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoadingUploadPhoto(true)
    let files = e.currentTarget.files
    let fileUpload = files ? files[0] : ''
    let formData = new FormData()
    formData.append('file', fileUpload)
    if (files) {
      fileApi
        .uploadCommonFile('reference', formData)
        .then((res) => {
          setPhotoUploadMode(true)
          setFormState({
            ...formState,
            photo: res.data.path,
          })
          setLoadingUploadPhoto(false)
        })
        .catch((e) => {
          setPhotoUploadMode(false)
          setLoadingUploadPhoto(false)
          notification.open({
            className: 'noti noti-error',
            message: (
              <div className="flex items-center">
                <img src={ERROR} alt="error" width={50} className="mr-2.5" />
                {t('popupErrorGeneral')}
              </div>
            ),
            placement: 'topRight',
            closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
            duration: 3,
          })
        })
    }
  }

  //click button remove ref photo
  const removeRefPhoto = () => {
    setPhotoUploadMode(false)
    setFormState({
      ...formState,
      photo: '',
    })
  }

  useEffect(() => {
    focusById(stockKey[mode === MODE.CREATE ? 'n-input' : 'libelle-long'])
  }, [])

  return (
    <SectionLayout title="Identification">
      <form className="identification-form">
        <Row>
          <Col lg={24} xl={{ span: 8 }}>
            <Row
              className={
                identificationError.find((item) => item.name === 'number')
                  ?.errorMessage
                  ? 'mb-5'
                  : 'mb-2.5'
              }
            >
              <Col lg={3} xl={{ span: 6 }} className="form-label require-filed">
                N°
              </Col>
              <Col lg={21} xl={{ span: 9, offset: 1 }}>
                <CInput
                  id={stockKey['n-input']}
                  nextId={stockKey['Langue-libellé']}
                  name="number"
                  format={removeTonesUpperCase}
                  value={formState.number}
                  maxLength={20}
                  required
                  className="custom-input"
                  onChange={onChangeInput}
                  errorMessageForce={
                    identificationError.find((item) => item.name === 'number')
                      ?.errorMessage
                  }
                  disabled={
                    props.action !== ActionOnReference.duplicate &&
                    (mode === MODE.VIEW || mode === MODE.EDIT)
                  }
                />
              </Col>
            </Row>
            <Row className="mb-2.5">
              <Col lg={3} xl={{ span: 6 }} className="form-label require-filed">
                Libellé long
              </Col>
              <Col lg={21} xl={{ span: 17, offset: 1 }}>
                <CInput
                  id={stockKey['libelle-long']}
                  nextId={stockKey['Libellé court']}
                  previousId={stockKey['Langue-libellé']}
                  name="libelle_long"
                  value={formState.libelle_long}
                  required
                  maxLength={120}
                  className="custom-input"
                  onChange={onChangeInput}
                  errorMessageForce={
                    identificationError.find(
                      (item) => item.name === 'libelle_long'
                    )?.errorMessage
                  }
                  disabled={mode === MODE.VIEW}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Row>
                  <Col lg={5} xl={{ span: 12 }} className="form-label">
                    Marque
                  </Col>
                  <Col
                    lg={{ span: 18, offset: 1 }}
                    xl={{ span: 10, offset: 2 }}
                  >
                    <CInput
                      id={stockKey['Marque']}
                      nextId={stockKey['Origine']}
                      previousId={stockKey['Libellé court']}
                      name="marque"
                      value={formState.marque}
                      maxLength={10}
                      className="custom-input"
                      onChange={onChangeInput}
                      disabled={mode === MODE.VIEW}
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={{ span: 10, offset: 1 }}>
                <Row>
                  <Col
                    lg={{ span: 10, offset: 1 }}
                    xl={{ span: 8, offset: 1 }}
                    className="form-label"
                  >
                    Origine
                  </Col>
                  <Col lg={{ span: 11, offset: 1 }} xl={{ span: 14 }}>
                    <AutocompletePays
                      id={stockKey['Origine']}
                      nextId={stockKey['Famille']}
                      previousId={stockKey['Marque']}
                      name="origine"
                      maxLength={22}
                      className="auto-complete-identification"
                      cbUpdateForm={onUpdateAutoComplete}
                      onChangeField={onChangeAutoComplete}
                      initValue={formState.origine}
                      disable={mode === MODE.VIEW}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col lg={24} xl={{ span: 15, offset: 1 }}>
            <Row align="middle">
              <Col lg={18} xl={{ span: 18 }}>
                <Row className="mb-2.5">
                  <Col lg={12}>
                    <Row>
                      <Col lg={5} xl={{ span: 10 }} className="form-label">
                        Langue libellé
                      </Col>
                      <Col lg={{ span: 18 }} xl={{ span: 10 }}>
                        <CInput
                          id={stockKey['Langue-libellé']}
                          nextId={stockKey['libelle-long']}
                          previousId={stockKey['n-input']}
                          type={[InputType.SELECT]}
                          value={
                            formState.langua_libelle
                              ? formState.langua_libelle
                              : 'fr'
                          }
                          options={optionsLanguage}
                          name="langua_libelle"
                          onChange={onChangeSelect}
                          className="custom-select h-full float-left"
                          disabled={mode === MODE.VIEW}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={{ span: 11, offset: 1 }}>
                    <Row>
                      <Col span={10} offset={1} className="form-label">
                        Client
                      </Col>
                      <Col span={13}>
                        <CInput
                          name="client"
                          value={
                            mode === MODE.CREATE
                              ? localStorage.getItem(
                                  Define.CHOOSING_CLIENT_CODENOM
                                )
                              : initData?.client
                          }
                          className="custom-input"
                          disabled
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mb-2.5">
                  <Col lg={{ span: 5 }} className="form-label">
                    Libellé court
                  </Col>
                  <Col lg={{ span: 19 }}>
                    <CInput
                      id={stockKey['Libellé court']}
                      nextId={stockKey['Marque']}
                      previousId={stockKey['libelle-long']}
                      name="libelle_court"
                      value={formState.libelle_court}
                      maxLength={80}
                      className="custom-input"
                      onChange={onChangeInput}
                      disabled={mode === MODE.VIEW}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <Row>
                      <Col lg={{ span: 10 }} className="form-label">
                        Famille
                      </Col>
                      <Col lg={{ span: 14 }}>
                        <CInput
                          id={stockKey['Famille']}
                          nextId={stockKey['Sous-Famille']}
                          previousId={stockKey['Origine']}
                          name="famille"
                          format={removeTones}
                          value={formState.famille}
                          maxLength={43}
                          className="custom-input"
                          onChange={onChangeInput}
                          disabled={mode === MODE.VIEW}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={{ span: 11, offset: 1 }}>
                    <Row>
                      <Col span={10} offset={1} className="form-label">
                        Sous-Famille
                      </Col>
                      <Col span={13}>
                        <CInput
                          id={stockKey['Sous-Famille']}
                          nextId={stockKey['ajouter-image']}
                          previousId={stockKey['Famille']}
                          name="sous_famille"
                          format={removeTones}
                          value={formState.sous_famille}
                          maxLength={43}
                          className="custom-input"
                          onChange={onChangeInput}
                          disabled={mode === MODE.VIEW}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }}>
                <div
                  tabIndex={0}
                  id={stockKey['ajouter-image']}
                  data-previous-id={stockKey['Sous-Famille']}
                  data-next-id={stockKey['model']}
                  style={{
                    backgroundImage: `${
                      formState.photo
                        ? `url(${baseURL}/files/common/show/reference/${formState.photo})`
                        : `url(${DEFAULT_IMAGE})`
                    }`,
                    height: 100,
                  }}
                  className="bg-center bg-no-repeat bg-contain relative identification-default-img"
                >
                  {/* loading when upload ref photo */}
                  {loadingUploadPhoto ? (
                    <Spin className="spin-styled" />
                  ) : (
                    <>
                      {/* if photo is uploaded, button delete appears*/}
                      {mode === MODE.VIEW ? null : photoUploadMode ? (
                        <img
                          src={BTN_DELETE}
                          alt="btn-delete"
                          style={{ width: 15 }}
                          className="absolute top-2 right-2 cursor-pointer"
                          onClick={removeRefPhoto}
                        />
                      ) : (
                        /* if photo is not uploaded, button add + text ajouter photo appears*/
                        <label className="cursor-pointer absolute ajouter-photo">
                          <input
                            className="hidden"
                            type="file"
                            accept=".jpg, .png"
                            onChange={uploadRefPhoto}
                            //clear cache after each time upload file
                            onClick={(e) =>
                              ((e.target as HTMLInputElement).value = '')
                            }
                          />
                          <div>
                            <img
                              src={BTN_UPLOAD}
                              alt="btn-upload"
                              className="block"
                              style={{ margin: '0 auto' }}
                            />
                            <p className="text-secondary -mt-2 mb-0 font-semibold text-center w-max">
                              Ajouter photo
                            </p>
                          </div>
                        </label>
                      )}
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    </SectionLayout>
  )
}

export default Identification
