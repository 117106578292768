import { LockOutlined } from '@ant-design/icons'
import { Col, Popconfirm, Row, Spin, notification } from 'antd'
import { cloneDeep, isEmpty, uniqBy } from 'lodash'
import { ReactNode, useEffect, useState } from 'react'
import CSwitch from '../../../components/Common/CSwitch/CSwitch'
import SelectList from '../../../components/Common/SelectList/SelectList'
import { InputType } from '../../../enum/InputType'
import {
  formatDecimal3,
  formatTwoDigits,
  isGreatThan,
  isLessThan,
  removeTonesUpperCase,
  validateHasSameLastDigit_8_13_14,
} from '../../../utils'
import ITable from './component/ITable'
import SectionLayout from './component/SectionLayout'
import { InvalidProperty, NameAndValue } from './component/Wrapper'
import {
  ActionParam,
  DataTableParam,
  ForceShowError,
  ForceShowWarning,
  FormatRowCol,
  ObserveAndTransform,
  TitleParam,
} from './component/model'
import firstLine from './data/init.data.json'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import Icone from '../../../assets/icons/dropdown-vue.svg'
import CLOSE_ICON from '../../../assets/images/close-success.png'
import ERROR from '../../../assets/images/error.png'
import { MODE } from '../../../enum/mode'
import { focusById } from '../../../hook/usePressTab'
import referenceApi from '../../../http/referenceApi'
import VarianteInput from '../components/VarianteInput'
import { getIndexes } from '../modal/type/commande/func'
import {
  allLinesAreValid,
  checkDuplicateEANVarianteAllLines,
  getValueFromLine,
  showWarningCheckDuplicateEANVariante,
} from './func'

interface ActionProps {
  isUseQteVariable?: boolean
  isDisablePlusBtn?: boolean
  isDisabledQTEToggle?: boolean
  isDisableModel?: boolean
  isDisableVersion?: boolean

  onToggleQTE?(value: boolean): void

  onClickPlusBtn?(): void

  dataLength: number
}

interface PackingProps {
  mode: MODE
  initData?: DataTableParam[][]
  onChangeData?: (value: DataTableParam[][]) => void
  onCheckValid?: (value: boolean) => void
  onDeleteLine?: (id: string) => void
  onBackToInit?: (isInit: boolean) => void
  alert?: ReactNode
  enableVariante?: boolean
  onChangeQteVar?: (isUseQteVar: boolean) => void
  qteVariable?: boolean
}

const Action = (props: ActionProps) => {
  const stockKey = useSelector((state: RootState) => state.selector).data.stock
  const onChangeQTE = (value: boolean) => {
    if (props?.onToggleQTE) {
      props?.onToggleQTE(value)
    }
  }

  const onClickPlusBtn = (): void => {
    if (props?.isDisablePlusBtn) return
    if (props?.onClickPlusBtn) props.onClickPlusBtn()
  }

  function getNextFocusIds() {
    let nextId = ''
    for (let i = 0; i < props.dataLength; i++) {
      nextId = `${nextId}, conditionementsnom-${i}, conditionementsicon-edit-${i}`
    }
    return `${nextId},fournisseurnom-0, ${stockKey['DLUO']}, ${stockKey['Supprimer']}, ${stockKey['Chargement']}`
  }

  return (
    <Row gutter={32} align="middle">
      <Col className="flex items-center gap-3">
        <span className="form-label">Model</span>
        <SelectList
          id={stockKey['model']}
          previousId={stockKey['ajouter-image']}
          nextId={`${stockKey['qte-variable']}, ${stockKey['version']}`}
          disabled={props.isDisableModel}
          width={180}
          options={[{ value: 'model-1', label: 'Model 1' }]}
        />
      </Col>
      <Col className="flex items-center gap-3">
        <Row gutter={6} align="middle">
          <Col className="flex items-center">
            <span className="form-label mr-3">Qte Variable</span>

            <CSwitch
              previousId={stockKey['model']}
              id={stockKey['qte-variable']}
              nextId={stockKey['version']}
              disabled={props?.isDisabledQTEToggle}
              checked={props?.isUseQteVariable}
              defaultChecked={false}
              onChange={onChangeQTE}
            />
          </Col>
          {props?.isDisabledQTEToggle && (
            <Col>
              <LockOutlined style={{ color: 'red' }} />
            </Col>
          )}
        </Row>
      </Col>
      <Col className="flex items-center gap-3">
        <span className="form-label">Version</span>
        <SelectList
          id={stockKey['version']}
          previousId={`${stockKey['qte-variable']}, ${stockKey['model']}`}
          nextId={getNextFocusIds()}
          disabled={props.isDisableVersion}
          width={160}
          options={[{ value: 'tous', label: 'Toutes' }]}
        />
      </Col>
      <Col className="flex items-center gap-3">
        <i
          className={`${
            props?.isDisablePlusBtn ? 'icon-add-plus-disable' : 'icon-add-plus'
          }`}
          onClick={onClickPlusBtn}
        ></i>
      </Col>
    </Row>
  )
}

//---------

const firstLineData = JSON.parse(JSON.stringify(firstLine)) as DataTableParam[]
const iconeIndex = firstLineData.findIndex((item) => item.name === 'icone')
const codeBarreIndex = firstLineData.findIndex(
  (item) => item.name === 'code_barre'
)
firstLineData[iconeIndex].value = Icone
firstLineData[codeBarreIndex].maxLength = 20
firstLineData[codeBarreIndex].format = removeTonesUpperCase
firstLineData[codeBarreIndex].type = [InputType.ALL_TEXT]
const initData: DataTableParam[][] = [cloneDeep(firstLineData)]

const formatCols: FormatRowCol[] = [
  {
    name: 'niveau',
    format: (value: string) => formatTwoDigits(Number(value)),
  },
  {
    name: 'code_barre',
    format: removeTonesUpperCase,
  },
]

const Packing = (props: PackingProps) => {
  const stockKey = useSelector((state: RootState) => state.selector).data.stock
  const cInputProps = {
    className: 'border-children-cell-table text-center',
    requiredMessage: '',
    validates: [],
  }

  const [header] = useState<TitleParam[]>([
    {
      label: 'Nom',
      required: true,
      name: 'nom',
      cInputProps: {
        ...cInputProps,
        requiredMessage: ' ',
        maxLength: 20,
        cellClass: 'py-3',
      },
    },
    {
      label: 'Icône',
      required: true,
      name: 'icone',
      cInputProps: {
        ...cInputProps,
        requiredMessage: ' ',
        type: [InputType.IMG],
      },
    },
    {
      label: 'Niveau',
      required: true,
      name: 'niveau',
      cInputProps: {
        ...cInputProps,
        validates: [],
        requiredMessage: '',
        disabled: true,
      },
    },
    {
      label: 'Qte / contenant',
      required: true,
      name: 'qte_contenant',
      cInputProps: {
        ...cInputProps,
        requiredMessage: ' ',
        maxLength: 10,
        type: [InputType.NUMBER_INTEGER_POS],
        validates: [
          {
            order: 0,
            validateFunction: (value) => isGreatThan(Number(value), 0),
            message: 'La valeur doit être supérieure à 0',
            realtime: true,
          },
        ],
      },
      isHide: true,
    },
    {
      label: 'Version',
      required: true,
      name: 'type',
      cInputProps: {
        ...cInputProps,
        requiredMessage: ' ',
        disabled: true,
        className: `w-16 + ${cInputProps.className}`,
      },
    },
    {
      label: 'Attendu',
      required: true,
      name: 'att_enable',
      cInputProps: {
        ...cInputProps,
        requiredMessage: ' ',
        type: [InputType.SWITCH],
        maxLength: 10,
      },
    },
    {
      label: 'Poids Net',
      required: true,
      name: 'poids_net',

      cInputProps: {
        ...cInputProps,
        requiredMessage: ' ',
        className: cInputProps.className + ' w-28',
        type: [InputType.NUMBER_FLOAT_POS, InputType.NUMBER_INTEGER_POS],
        format: formatDecimal3,
        formatOnBlur: true,
        maxLength: 15,
        validates: [
          {
            order: 0,
            validateFunction: (value) => isGreatThan(Number(value), 0),
            message: 'La valeur doit être supérieure à 0',
            realtime: true,
          },
          {
            order: 1,
            validateFunction: (value) =>
              isLessThan(Number(value), Math.pow(10, 15)),
            message: 'La valeur doit être inférieure à 10^15',
            realtime: true,
          },
        ],
      },
    },
    {
      label: 'Poids Brut',
      required: true,
      name: 'poids_brut',
      cInputProps: {
        ...cInputProps,
        requiredMessage: ' ',
        className: cInputProps.className + ' w-30',
        type: [InputType.NUMBER_FLOAT_POS, InputType.NUMBER_INTEGER_POS],
        format: formatDecimal3,
        formatOnBlur: true,
        maxLength: 15,
        validates: [
          {
            order: 0,
            validateFunction: (value) => isGreatThan(Number(value), 0),
            message: 'La valeur doit être supérieure à 0',
            realtime: true,
          },
          {
            order: 1,
            validateFunction: (value) =>
              isLessThan(Number(value), Math.pow(10, 15)),
            message: 'La valeur doit être inférieure à 10^15',
            realtime: true,
          },
        ],
      },
    },
    {
      label: 'Poids Add.',
      required: true,
      name: 'poids_add',
      cInputProps: {
        ...cInputProps,
        requiredMessage: ' ',
        className: cInputProps.className + ' w-30',
        type: [InputType.NUMBER_FLOAT_POS, InputType.NUMBER_INTEGER_POS],
        format: formatDecimal3,
        formatOnBlur: true,
        maxLength: 15,
        validates: [
          {
            order: 0,
            validateFunction: (value) =>
              isLessThan(Number(value), Math.pow(10, 15)),
            message: 'La valeur doit être inférieure à 10^15',
            realtime: true,
          },
        ],
      },
    },
    {
      label: 'Longueur',
      required: true,
      name: 'longueur',

      cInputProps: {
        ...cInputProps,
        className: cInputProps.className + ' w-26',
        requiredMessage: ' ',
        maxLength: 15,
        format: formatDecimal3,
        formatOnBlur: true,
        type: [InputType.NUMBER_FLOAT_POS, InputType.NUMBER_INTEGER_POS],
        validates: [
          {
            order: 0,
            validateFunction: (value) => isGreatThan(Number(value), 0),
            message: 'La valeur doit être supérieure à 0',
            realtime: true,
          },
          {
            order: 1,
            validateFunction: (value) =>
              isLessThan(Number(value), Math.pow(10, 15)),
            message: 'La valeur doit être inférieure à 10^15',
            realtime: true,
          },
        ],
      },
    },
    {
      label: 'Largeur',
      required: true,
      name: 'largeur',
      cInputProps: {
        ...cInputProps,
        className: cInputProps.className + ' w-26',
        requiredMessage: ' ',
        maxLength: 15,
        formatOnBlur: true,
        format: formatDecimal3,
        type: [InputType.NUMBER_FLOAT_POS, InputType.NUMBER_INTEGER_POS],
        validates: [
          {
            order: 0,
            validateFunction: (value) => isGreatThan(Number(value), 0),
            message: 'La valeur doit être supérieure à 0',
            realtime: true,
          },
          {
            order: 1,
            validateFunction: (value) =>
              isLessThan(Number(value), Math.pow(10, 15)),
            message: 'La valeur doit être inférieure à 10^15',
            realtime: true,
          },
        ],
      },
    },
    {
      label: 'Hauteur',
      required: true,
      name: 'hauteur',
      cInputProps: {
        ...cInputProps,
        requiredMessage: ' ',
        className: cInputProps.className + ' w-26',
        maxLength: 15,
        formatOnBlur: true,
        format: formatDecimal3,
        type: [InputType.NUMBER_FLOAT_POS, InputType.NUMBER_INTEGER_POS],
        validates: [
          {
            order: 0,
            validateFunction: (value) => isGreatThan(Number(value), 0),
            message: 'La valeur doit être supérieure à 0',
            realtime: true,
          },
          {
            order: 1,
            validateFunction: (value) =>
              isLessThan(Number(value), Math.pow(10, 15)),
            message: 'La valeur doit être inférieure à 10^15',
            realtime: true,
          },
        ],
      },
    },
    {
      label: 'Couches',
      name: 'couche',
      cInputProps: {
        ...cInputProps,
        className: cInputProps.className + ' w-26',
        requiredMessage: ' ',
        maxLength: 2,
        type: [InputType.NUMBER_INTEGER_POS],
        formatOnBlur: true,
        format: (couche) => {
          if (Number(couche) === 0) return ''
          return `${Number(couche)}`
        },
      },
    },
    {
      label: 'Type CB',
      required: true,
      name: 'typeCB',
      cInputProps: {
        ...cInputProps,
        requiredMessage: ' ',
        type: [InputType.SELECT],
        options: [
          { label: 'EAN', value: 'EAN' },
          { label: 'Autre', value: 'Autre' },
        ],
        defaultSelectedValue: 'Autre',
      },
    },
    {
      label: 'Code barre',
      required: true,
      name: 'code_barre',
      cInputProps: {
        ...cInputProps,
        validates: [
          // {
          //   order: 0,
          //   validateFunction: validateHasSameLastDigit_7_13_14,
          //   message: 'La valeur doit être supérieure à 0',
          //   realtime: true
          // },
        ],
        type: [InputType.ALL_TEXT],
        requiredMessage: ' ',
      },
    },
    {
      label: 'Variante',
      name: 'variante',

      cInputProps: {
        ...cInputProps,
        disabled: false,
        className: 'border-children-cell-table text-center w-[150px]',
        cellClass: 'bd-right-cell-table',
        width: 150,
        element: (props) => {
          return (
            <div
              className="wrapper-table table-cell border-children-cell-table"
              style={{ borderRight: '1px solid #e5e5e5' }}
            >
              <div className="flex items-center justify-center mx-3 box-input">
                <VarianteInput {...props} />
              </div>
            </div>
          )
        },
      },
    },
    {
      label: 'Rotation',
      name: 'rotation',
      cInputProps: {
        ...cInputProps,
        className: cInputProps.className + ' w-24',
        requiredMessage: ' ',
        type: [InputType.SELECT],
        options: [
          { label: 'A', value: 'A' },
          { label: 'B', value: 'B' },
          { label: 'C', value: 'C' },
          { label: 'D', value: 'D' },
        ],
        defaultSelectedValue: 'D',
      },
    },
    {
      label: 'Supports',
      name: 'support',
      cInputProps: { ...cInputProps, requiredMessage: ' ' },
    },
  ])

  const [data, setData] = useState<DataTableParam[][]>(
    props?.mode === MODE.EDIT || props.mode === MODE.VIEW ? [] : initData
  )
  const { t } = useTranslation()
  // const [isValidLastLine, setIsValidLastLine] = useState<boolean>(false);
  // const [numberOfNewLines, setNumberOfNewLines] = useState<number>(0);
  const [invalidList, setInvalidList] = useState<InvalidProperty[][]>([[]])
  const [actions, setActions] = useState<ActionParam[]>([])
  const [listNotFillRequired, setListNotFillRequired] = useState<string[][]>([
    [],
  ])
  const [forceShowError, setForceShowError] = useState<ForceShowError[][]>([[]])
  const [forceShowWarning, setForceShowWarning] = useState<
    ForceShowWarning[][]
  >([[]])
  const [disableLines, setDisableLines] = useState<number[]>([])
  const [hideCols, setHideCols] = useState<string[]>([])
  const [formatRows, setFormatRows] = useState<FormatRowCol[][]>([
    [
      {
        name: 'code_barre',
        format: removeTonesUpperCase,
      },
    ],
  ])
  const [resetFlags, setResetFlags] = useState<boolean[]>([])
  const [isDisabledQTEToggle, setIsDisabledQTEToggle] = useState<boolean>(false)
  //const [observeAndTransform, setObserveAndTransform] = useState<ObserveAndTransform[] | undefined>([])
  const [observeAndTransform] = useState<ObserveAndTransform[] | undefined>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  // qte
  const onToggleQTE = (value: boolean) => {
    if (props.onChangeQteVar) {
      props.onChangeQteVar(value)
    }
  }
  // useEffect(() => {
  //   if (!props?.initData) return
  //   for (let i = 0; i < props?.initData?.length; i++) {
  //     const currentLine = props.initData[i]
  //     const currentQte =
  //       currentLine.find((item) => item.name === 'qte') || props.qteVariable
  //     if (!currentQte) {
  //       //props.onChangeQteVar(true)
  //       return
  //     }
  //   }
  // }, [props.initData])

  useEffect(() => {
    if (!props?.initData) return
    const init = props.initData.sort((pre, cur) => {
      const preNiveau = Number(
        pre.find((item) => item.name === 'niveau')?.value
      )
      const curNiveau = Number(
        cur.find((item) => item.name === 'niveau')?.value
      )
      return preNiveau - curNiveau
    })
    const formatRowsClone = []
    for (let i = 0; i < init.length; i++) {
      const currentLine = init[i]
      const poidsNetIndex = currentLine.findIndex(
        (item) => item.name === 'poids_net'
      )
      const poidsBrutIndex = currentLine.findIndex(
        (item) => item.name === 'poids_brut'
      )
      const poidsAddIndex = currentLine.findIndex(
        (item) => item.name === 'poids_add'
      )
      const longueurIndex = currentLine.findIndex(
        (item) => item.name === 'longueur'
      )
      const largeurIndex = currentLine.findIndex(
        (item) => item.name === 'largeur'
      )
      const hauteurIndex = currentLine.findIndex(
        (item) => item.name === 'hauteur'
      )
      const qteContenantIndex = currentLine.findIndex(
        (item) => item.name === 'qte'
      )

      const versionIndex = currentLine.findIndex((item) => item.name === 'type')

      const niveauIndex = currentLine.findIndex(
        (item) => item.name === 'niveau'
      )

      const typeCbIndex = currentLine.findIndex(
        (item) => item.name === 'typeCB'
      )

      const codeBarreIndex = currentLine.findIndex(
        (item) => item.name === 'code_barre'
      )

      if (poidsNetIndex !== -1)
        init[i][poidsNetIndex].value = formatDecimal3(
          Number(init[i][poidsNetIndex].value)
        )
      if (poidsBrutIndex !== -1)
        init[i][poidsBrutIndex].value = formatDecimal3(
          Number(init[i][poidsBrutIndex].value)
        )
      if (poidsAddIndex !== -1)
        init[i][poidsAddIndex].value = formatDecimal3(
          Number(init[i][poidsAddIndex].value)
        )
      if (longueurIndex !== -1)
        init[i][longueurIndex].value = formatDecimal3(
          Number(init[i][longueurIndex].value)
        )
      if (largeurIndex !== -1)
        init[i][largeurIndex].value = formatDecimal3(
          Number(init[i][largeurIndex].value)
        )
      if (hauteurIndex !== -1)
        init[i][hauteurIndex].value = formatDecimal3(
          Number(init[i][hauteurIndex].value)
        )
      if (qteContenantIndex !== -1)
        init[i][qteContenantIndex].name = 'qte_contenant'
      if (qteContenantIndex !== -1)
        init[i][qteContenantIndex].value = String(
          init[i][qteContenantIndex].value
        )
      if (qteContenantIndex !== -1 && i === 0)
        init[i][qteContenantIndex].disabled = true
      if (niveauIndex !== -1) init[i][niveauIndex].disabled = true
      if (versionIndex !== -1) init[i][versionIndex].disabled = true
      if (typeCbIndex !== -1) {
        const typeCB = init[i][typeCbIndex].value
        if (typeCB === 'Autre') {
          init[i][codeBarreIndex].type = [InputType.ALL_TEXT]
          init[i][codeBarreIndex].format = removeTonesUpperCase
          init[i][codeBarreIndex].maxLength = 20
          formatRowsClone.push([
            {
              name: 'code_barre',
              format: removeTonesUpperCase,
            },
          ])

          init[i][codeBarreIndex].validates = (
            init[i][codeBarreIndex].validates || []
          )?.filter((item) => item.message !== 'Entrée invalide')
        } else {
          init[i][codeBarreIndex].type = [InputType.NUMBER_INTEGER_POS]
          init[i][codeBarreIndex].format = (value) => value
          init[i][codeBarreIndex].maxLength = 14
          init[i][codeBarreIndex].validates = [
            {
              message: 'Entrée invalide',
              validateFunction: validateHasSameLastDigit_8_13_14,
              order: 0,
              realtime: true,
            },
          ]
          formatRowsClone.push([
            {
              name: 'code_barre',
              format: (value: string) => String(value),
            },
          ])
        }
      }
    }
    setFormatRows(formatRowsClone)
    setData(init)
  }, [props?.initData])

  useEffect(() => {
    if (!props?.initData) return
    const forceShowErrorClone = []
    const forceShowWarningClone = []
    const disabledLinesClone = []
    const listNotFillRequiredClone = []
    const invalidListClone = []

    for (let i = 0; i < props.initData.length; i++) {
      invalidListClone.push([])
      forceShowErrorClone.push([])
      forceShowWarningClone.push([])
      disabledLinesClone.push(i)
      listNotFillRequiredClone.push([])
    }
    setForceShowError(forceShowErrorClone)
    setForceShowWarning(forceShowWarningClone)
    setDisableLines(disabledLinesClone)
    setListNotFillRequired(listNotFillRequiredClone)
    setInvalidList(invalidListClone)
  }, [props?.initData])

  useEffect(() => {
    let hideColsClone = cloneDeep(hideCols)
    let dataClone = cloneDeep(data)
    if (data.length === 0) dataClone = cloneDeep(props.initData || [])

    if (props.qteVariable) {
      hideColsClone.push('qte_contenant')
      hideColsClone.push('couche')
      for (let i = 0; i < dataClone.length; i++) {
        dataClone[i] = dataClone[i].filter(
          (item) => item.name !== 'qte_contenant' && item.name !== 'couche'
        )
      }
    } else {
      hideColsClone = hideColsClone.filter(
        (col) => col !== 'qte_contenant' && col !== 'couche'
      )
      for (let i = 0; i < dataClone.length; i++) {
        const qteContenant = getValueFromLine('qte_contenant', dataClone[i])
        dataClone[i] = dataClone[i].filter(
          (item) => item.name !== 'qte_contenant'
        )

        const item: DataTableParam = {
          name: 'qte_contenant',
          value: qteContenant || '1',
        }
        dataClone[i].push(item)

        dataClone[i].push({ name: 'couche', value: '', disabled: i < 2 })

        if (i === 0) item.disabled = true
      }
    }
    setData(dataClone)
    setHideCols(hideColsClone)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.qteVariable, props.initData])

  useEffect(() => {
    async function showHideVariante() {
      let hideColsClone = cloneDeep(hideCols)
      const forceShowWarningClone = cloneDeep(forceShowWarning)
      const dataClone = cloneDeep(data)

      if (!props.enableVariante) {
        hideColsClone.push(t('variante'))
      } else {
        hideColsClone = hideColsClone.filter((col) => col !== t('variante'))
        for (let i = 0; i < dataClone.length; i++) {
          const variante = dataClone[i].find(
            (item) => item.name === t('variante')
          )
          if (!variante?.value) {
            const item: DataTableParam = { name: t('variante'), value: '00' }
            dataClone[i].push(item)
          }
        }

        if (allLinesAreValid(dataClone))
          //when turn on variante switch, check EAN-variante duplicated of all lines in table
          try {
            const { arrIndexesOfDuplicatedLines } =
              await checkDuplicateEANVarianteAllLines(dataClone)

            //set warning for lines have EAN-variante duplicated
            arrIndexesOfDuplicatedLines.forEach((item) => {
              forceShowWarningClone[item].push({
                name: t('variante'),
                message: t('code_existed'),
              })
            })
          } catch (e) {}
      }

      setData(dataClone)
      setHideCols(hideColsClone)
      setForceShowWarning(forceShowWarningClone)
    }

    showHideVariante()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.enableVariante])

  useEffect(() => {
    const actionsClone = cloneDeep(actions) as ActionParam[]

    for (let i = 0; i < listNotFillRequired.length; i++) {
      if (isEmpty(listNotFillRequired[i])) {
        actionsClone[i] = {
          ...actionsClone[i],
          isFillAllRequired: true,
        }
      } else {
        actionsClone[i] = {
          ...actionsClone[i],
          isFillAllRequired: false,
          isValid: false,
        }
      }
    }
    for (let i = 0; i < invalidList.length; i++) {
      if (isEmpty(invalidList[i]))
        actionsClone[i] = {
          ...actionsClone[i],
          isValid: true,
        }
      else
        actionsClone[i] = {
          ...actionsClone[i],
          isValid: false,
        }
    }

    setActions(actionsClone)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listNotFillRequired, invalidList])

  useEffect(() => {
    if (props?.initData) {
      const actionsClone = cloneDeep(actions)
      const initLength = props.initData.length
      for (let i = 0; i < initLength; i++) {
        if (i < initLength - 1) {
          actionsClone[i] = {
            isAllowDelete: false,
            isAllowEdit: true,
            isFillAllRequired: true,
            isForceValid: true,
            isValid: true,
          }
        } else {
          actionsClone[i] = {
            isAllowDelete: true,
            isAllowEdit: true,
            isFillAllRequired: true,
            isForceValid: true,
            isValid: true,
          }
        }
      }
      setActions(actionsClone)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.initData])

  useEffect(() => {
    if (data?.length > 1) setIsDisabledQTEToggle(true)
    else if (
      actions[0]?.isForceValid &&
      actions[0]?.isValid &&
      actions[0]?.isFillAllRequired
    )
      setIsDisabledQTEToggle(true)
    else setIsDisabledQTEToggle(false)
  }, [data, actions])
  // add btn

  const onAddNewLine = () => {
    const dataClone = cloneDeep(data)
    const formatRowsClone = cloneDeep(formatRows)
    const actionClone = cloneDeep(actions)
    const forceShowErrorClone = cloneDeep(forceShowError)
    const forceShowWarningClone = cloneDeep(forceShowWarning)
    const listNotFillRequiredClone = cloneDeep(listNotFillRequired)

    formatRowsClone.push([
      {
        name: 'code_barre',
        format: removeTonesUpperCase,
      },
    ])
    const newData = calcNewLine(dataClone[dataClone.length - 1])
    const codeBarreIndex = newData.findIndex(
      (item) => item.name === 'code_barre'
    )

    newData[codeBarreIndex].maxLength = 20
    newData[codeBarreIndex].validates = []

    dataClone.push(newData)

    for (let i = 0; i < actionClone.length; i++) {
      actionClone[i].isAllowEdit = false
    }
    actionClone[actionClone.length - 1].isAllowDelete = false
    actionClone.push({
      isAllowDelete: true,
      isFillAllRequired: false,
      isForceValid: false,
      isValid: false,
      isAllowEdit: true,
    })

    forceShowErrorClone.push([])
    forceShowWarningClone.push([])
    listNotFillRequiredClone.push([])
    setData(dataClone)
    setFormatRows(formatRowsClone)
    setActions(actionClone)
    setForceShowError(forceShowErrorClone)
    setForceShowWarning(forceShowWarningClone)
    setListNotFillRequired(listNotFillRequiredClone)
    // focus on nom field in new line
    focusById(`conditionementsnom-${dataClone.length - 1}`, 500)
  }

  const calcNewLine = (oldLine: DataTableParam[]): DataTableParam[] => {
    const oldNiveau = oldLine.find((item) => item.name === 'niveau')
    let newData = cloneDeep(initData[0])
    const indexOldNiveau = newData.findIndex((item) => item.name === 'niveau')
    const indexQteContenant = newData.findIndex(
      (item) => item.name === 'qte_contenant'
    )
    newData[indexOldNiveau].value = formatTwoDigits(
      Number(oldNiveau?.value) + 1
    )
    newData[indexQteContenant].disabled = false
    newData[indexQteContenant].value = ''
    const oldLongueur = oldLine.find((item) => item.name === 'longueur')
    const oldLargeur = oldLine.find((item) => item.name === 'largeur')
    const oldHauteur = oldLine.find((item) => item.name === 'hauteur')
    if (props.enableVariante) {
      newData.push({ name: t('variante'), value: '00' })
    }

    newData = newData.map((item) => {
      if (item.name === 'longueur')
        item.value = formatDecimal3(Number(oldLongueur?.value || ''))
      if (item.name === 'largeur')
        item.value = formatDecimal3(Number(oldLargeur?.value || ''))
      if (item.name === 'hauteur')
        item.value = formatDecimal3(Number(oldHauteur?.value || ''))
      if (props.qteVariable) {
        const oldPoidsNet = oldLine.find((item) => item.name === 'poids_net')
        const oldPoidsBrut = oldLine.find((item) => item.name === 'poids_brut')

        if (item.name === 'poids_net')
          item.value = formatDecimal3(Number(oldPoidsNet?.value || ''))
        if (item.name === 'poids_brut')
          item.value = formatDecimal3(Number(oldPoidsBrut?.value || ''))
      }
      return item
    })

    return newData
  }

  const onChangeDataTable = async (
    index: number,
    nameAndValue: NameAndValue
  ) => {
    if (props.onBackToInit) props.onBackToInit(false)
    const dataClone = cloneDeep(data)
    const forceShowErrorClone = cloneDeep(forceShowError)
    let forceShowWarningClone = cloneDeep(forceShowWarning)
    let currentLine = dataClone[index]
    let preLine: DataTableParam[] = []

    if (index > 0) preLine = dataClone[index - 1]

    let currentChange = currentLine.find(
      (item) => item.name === nameAndValue.name
    )

    if (!currentChange) return

    currentChange.value = nameAndValue.value

    currentLine = currentLine.filter((item) => item.name !== nameAndValue.name)
    currentLine.push(currentChange)

    if (
      !props.qteVariable &&
      currentChange.name === 'qte_contenant' &&
      index > 0
    ) {
      const prePoidsNet = preLine.find(
        (item) => item.name === 'poids_net'
      )?.value
      const prePoidsBrut = preLine.find(
        (item) => item.name === 'poids_brut'
      )?.value
      const qteContenant = nameAndValue.value

      const newPoidsNet = Number(prePoidsNet) * Number(qteContenant)
      const newPoidsBrut = Number(prePoidsBrut) * Number(qteContenant)

      currentLine = currentLine.map((item) => {
        if (item.name === 'poids_net') item.value = formatDecimal3(newPoidsNet)
        if (item.name === 'poids_brut')
          item.value = formatDecimal3(newPoidsBrut)
        return item
      })

      if (newPoidsNet >= Math.pow(10, 15) || newPoidsBrut >= Math.pow(10, 15)) {
        if (newPoidsNet >= Math.pow(10, 15)) {
          forceShowErrorClone[index] = forceShowErrorClone[index].filter(
            (item) => item.name !== 'poids_net'
          )
          forceShowErrorClone[index].push({
            name: 'poids_net',
            message: 'La valeur doit être inférieure à 10^15',
          })
        }

        if (newPoidsBrut >= Math.pow(10, 15)) {
          forceShowErrorClone[index] = forceShowErrorClone[index].filter(
            (item) => item.name !== 'poids_brut'
          )
          forceShowErrorClone[index].push({
            name: 'poids_brut',
            message: 'La valeur doit être inférieure à 10^15',
          })
        }
        setForceShowError(forceShowErrorClone)
      } else {
        forceShowErrorClone[index] = forceShowErrorClone[index].filter(
          (item) => item.name !== 'poids_net' && item.name !== 'poids_brut'
        )
        setForceShowError(forceShowErrorClone)
      }

      // handle couche
      const coucheIndex = getIndexes('couche', currentLine)
      if (coucheIndex === -1) {
      } else {
        currentLine[coucheIndex].value = ''

        if (index > 1) {
          if (!qteContenant || Number(qteContenant) === 0)
            currentLine[coucheIndex].disabled = true
          else {
            currentLine[coucheIndex].disabled = false
            currentLine[coucheIndex].validates = [
              {
                name: 'lessThanOrEqualQte',
                message: 'Max = Qte/contenant',
                validateFunction: (couche) => {
                  if (!couche) return true
                  if (couche === '-') return true
                  return isLessThan(Number(couche), Number(qteContenant) + 1)
                },
                order: 0,
                realtime: true,
              },
            ]
          }
        }
      }
    }

    if (
      currentChange.name === 'poids_net' ||
      currentChange.name === 'poids_brut'
    ) {
      forceShowErrorClone[index] = forceShowErrorClone[index].filter(
        (item) => item.message !== 'Veuillez vérifier le poids renseigné'
      )
      if (Number(currentChange.value) < Math.pow(10, 15)) {
        forceShowErrorClone[index] = forceShowErrorClone[index].filter(
          (item) =>
            !(
              item.name === currentChange?.name &&
              item.message === 'La valeur doit être inférieure à 10^15'
            )
        )
      }
      setForceShowError(forceShowErrorClone)
    }

    if (currentChange.name === 'typeCB') {
      let formatRowsClone = cloneDeep(formatRows)
      formatRowsClone[index] = formatRowsClone[index].filter(
        (item) => item.name !== 'code_barre'
      )
      const indexOfCodeBarre = dataClone[index].findIndex(
        (item) => item.name === 'code_barre'
      )
      dataClone[index][indexOfCodeBarre].value = ''
      if (currentChange.value === 'Autre') {
        dataClone[index][indexOfCodeBarre].type = [InputType.ALL_TEXT]
        dataClone[index][indexOfCodeBarre].format = removeTonesUpperCase
        dataClone[index][indexOfCodeBarre].maxLength = 20
        formatRowsClone[index].push({
          name: 'code_barre',
          format: removeTonesUpperCase,
        })

        dataClone[index][indexOfCodeBarre].validates = data[index][
          indexOfCodeBarre
        ].validates?.filter((item) => item.message !== 'Entrée invalide')
      } else {
        dataClone[index][indexOfCodeBarre].type = [InputType.NUMBER_INTEGER_POS]
        dataClone[index][indexOfCodeBarre].format = (value) => value
        dataClone[index][indexOfCodeBarre].maxLength = 14
        dataClone[index][indexOfCodeBarre].validates = [
          {
            message: 'Entrée invalide',
            validateFunction: validateHasSameLastDigit_8_13_14,
            order: 0,
            realtime: true,
          },
        ]
        formatRowsClone[index].push({
          name: 'code_barre',
          format: (value) => value,
        })
      }

      setFormatRows(formatRowsClone)
    }

    dataClone[index] = currentLine
    if (props?.onChangeData) props.onChangeData(dataClone)
    setData(dataClone)

    //show warning after edit variante or code barre
    if (
      currentChange.name === 'variante' ||
      currentChange.name === 'code_barre'
    ) {
      forceShowWarningClone = await showWarningCheckDuplicateEANVariante({
        data: dataClone,
        index,
        forceShowWarningClone,
        name: t('variante'),
        message: t('code_existed'),
        enableVariante: props.enableVariante || false,
      })
    }

    setForceShowWarning(forceShowWarningClone)
  }

  const onCheckInvalid = (
    index: number,
    invalidProperty: InvalidProperty | null
  ) => {
    const inValidListClone = cloneDeep(invalidList)
    if (!invalidProperty) inValidListClone[index] = []
    else {
      const indexOfProperty = inValidListClone[index].findIndex(
        (item) => item.name === invalidProperty?.name
      )
      if (indexOfProperty === -1) inValidListClone[index].push(invalidProperty)
      else inValidListClone[index][indexOfProperty] = invalidProperty
    }

    setInvalidList(inValidListClone)
  }

  const onCheckFillRequired = (index: number, list: string[]) => {
    const listNotFillRequiredClone = cloneDeep(listNotFillRequired)
    if (!isEmpty(listNotFillRequired)) {
      const oldLength = listNotFillRequired[index].length
      const newLength = list.length
      let isChange = false
      if (oldLength === newLength) {
        for (let i = 0; i < oldLength; i++) {
          if (!listNotFillRequired[index].includes(list[i])) {
            isChange = true
            break
          }
        }
      } else isChange = true
      listNotFillRequiredClone[index] = list
      if (props.qteVariable) {
        for (let i = 0; i < listNotFillRequiredClone.length; i++) {
          listNotFillRequiredClone[i] = listNotFillRequiredClone[i].filter(
            (item) => item !== 'qte_contenant'
          )
        }
      }
      if (isChange) setListNotFillRequired(listNotFillRequiredClone)
    }
  }

  const checkPoids = (index: number, data: DataTableParam[][]): boolean => {
    const currentData = data[index]
    const indexOfPoidsNet = currentData.findIndex(
      (item) => item.name === 'poids_net'
    )
    const indexOfPoidsBrut = currentData.findIndex(
      (item) => item.name === 'poids_brut'
    )

    const currentPoidsNet = currentData[indexOfPoidsNet].value
    const currentPoidsBrut = currentData[indexOfPoidsBrut].value

    if (Number(currentPoidsNet) > Number(currentPoidsBrut)) return false

    return true
  }

  const onCheckValidByBtn = async (index: number) => {
    const forceShowErrorClone = cloneDeep(forceShowError)
    const forceShowWarningClone = cloneDeep(forceShowWarning)
    const actionsClone = cloneDeep(actions)
    let disableLinesClone = cloneDeep(disableLines)
    if (!checkPoids(index, data)) {
      forceShowErrorClone[index] = forceShowErrorClone[index].filter(
        (item) => item.name !== 'poids_net' && item.name !== 'poids_brut'
      )
      forceShowErrorClone[index].push(
        {
          name: 'poids_net',
          message: 'Veuillez vérifier le poids renseigné',
        },
        {
          name: 'poids_brut',
          message: 'Veuillez vérifier le poids renseigné',
        }
      )

      actionsClone[index].isForceValid = false
    } else {
      const dataClone = cloneDeep(data)
      forceShowErrorClone[index] = forceShowError[index].filter(
        (item) => item.name !== 'poids_net' && item.name !== 'poids_brut'
      )
      actionsClone[index].isForceValid = true
      for (let i = 0; i < actionsClone.length; i++) {
        actionsClone[i].isAllowEdit = true
      }
      disableLinesClone.push(index)
      disableLinesClone = uniqBy(disableLinesClone, (e) => e)
      for (let i = 0; i < dataClone[index].length; i++) {
        const currentCell = dataClone[index][i]
        const name = currentCell.name
        if (
          (name === 'couche' || name === 'support') &&
          currentCell.value?.trim() === ''
        )
          dataClone[index][i].value = '-'
      }

      setData(dataClone)
      focusById(`conditionementsicon-edit-${index}`, 500)
    }

    forceShowWarningClone[index] = forceShowWarningClone[index].filter(
      (item) => item.name !== t('variante')
    )
    setForceShowWarning(forceShowWarningClone)
    setForceShowError(forceShowErrorClone)
    setActions(actionsClone)
    setDisableLines(disableLinesClone)
  }

  const onAllowEdit = (index: number) => {
    let disableLinesClone = cloneDeep(disableLines)
    let actionsClone = cloneDeep(actions)
    const dataClone = cloneDeep(data)

    disableLinesClone = disableLinesClone.filter((item) => item !== index)
    actionsClone[index].isForceValid = false
    actionsClone[index].isAllowEdit = true
    for (let i = 0; i < actionsClone.length; i++) {
      if (i !== index) {
        actionsClone[i].isAllowEdit = false
      }
    }

    for (let i = 0; i < dataClone[index].length; i++) {
      const currentCell = dataClone[index][i]
      const { name, value } = currentCell
      if ((name === 'couche' || name === 'support') && value === '-') {
        dataClone[index][i].value = ''
      }

      if (name === 'couche' && index < 2) dataClone[index][i].disabled = true
      if (name === 'couche' && index > 1) {
        const qteContenant = getValueFromLine('qte_contenant', dataClone[index])
        dataClone[index][i].validates = [
          {
            name: 'lessThanOrEqualQte',
            message: 'Max = Qte/contenant',
            validateFunction: (couche) => {
              if (!couche) return true
              if (couche === '-') return true
              return isLessThan(Number(couche), Number(qteContenant) + 1)
            },
            order: 0,
            realtime: true,
          },
        ]
      }
    }

    setData(dataClone)
    setActions(actionsClone)
    setDisableLines(disableLinesClone)
  }

  const onDeleteLine = (index: number) => {
    if (props.mode === MODE.EDIT) {
      const id = data[index].find((item) => item.name === 'id')?.value

      if (id) {
        setIsLoading(true)
        referenceApi
          .checkCdnAllowDeleteOrNot(id)
          .then((res) => {
            if (res.data.status) {
              deleteLine(index)
            } else {
              notification.open({
                className: 'noti noti-error',
                message: (
                  <div className="flex items-center">
                    <img
                      src={ERROR}
                      alt="error"
                      width={50}
                      className="mr-2.5"
                    />
                    Suppression impossible:
                    <br />
                    Élément existant à une opération
                  </div>
                ),
                placement: 'topRight',
                closeIcon: (
                  <img src={CLOSE_ICON} alt="close" className="ml-28" />
                ),
                duration: 3,
              })
            }
          })
          .catch((err) => {})
          .finally(() => {
            setIsLoading(false)
          })
      } else deleteLine(index)
    } else deleteLine(index)
  }

  const deleteLine = (index: number) => {
    const dataClone = cloneDeep(data)
    const forceShowErrorClone = cloneDeep(forceShowError)
    const resetFlagClone = cloneDeep(resetFlags)
    let formatRowsClone = cloneDeep(formatRows)
    let actionsClone = cloneDeep(actions)
    let disabledLinesClone = cloneDeep(disableLines)
    let invalidListClone = cloneDeep(invalidList)
    let listNotFillRequiredClone = cloneDeep(listNotFillRequired)
    const forceShowWarningClone = cloneDeep(forceShowWarning)

    const id = dataClone[index].find((item) => item.name === 'id')?.value
    if (index === 0) {
      if (data.length === 1 && props?.onBackToInit) props.onBackToInit(true)
      dataClone[0] = cloneDeep(firstLineData)
      resetFlagClone[0] = !resetFlagClone[0]
      actionsClone[0] = {
        isAllowDelete: true,
        isFillAllRequired: false,
        isForceValid: false,
        isValid: false,
        isAllowEdit: true,
      }
    } else {
      dataClone.splice(index, 1)
      actionsClone.splice(index, 1)
      actionsClone[actionsClone.length - 1].isAllowDelete = true
      for (let i = 0; i < actionsClone.length; i++) {
        actionsClone[i].isAllowEdit = true
      }
      forceShowWarningClone.splice(index, 1)

      forceShowErrorClone.splice(index, 1)
      formatRowsClone.splice(index, 1)
      invalidListClone.splice(index, 1)
      listNotFillRequiredClone.splice(index, 1)
    }
    forceShowErrorClone[index] = []
    forceShowWarningClone[index] = []
    disabledLinesClone = disabledLinesClone.filter((item) => item !== index)

    if (props?.onDeleteLine) props.onDeleteLine(id || '')
    if (props?.onChangeData) props.onChangeData(dataClone)

    setData(dataClone)
    setForceShowError(forceShowErrorClone)
    setForceShowWarning(forceShowWarningClone)
    setResetFlags(resetFlagClone)
    setDisableLines(disabledLinesClone)
    setActions(actionsClone)
    setFormatRows(formatRowsClone)
    setInvalidList(invalidListClone)
    setListNotFillRequired(listNotFillRequiredClone)
  }

  useEffect(() => {
    if (!props?.onCheckValid) return
    const isAllValid = actions.every(
      (item) => item.isForceValid && item.isFillAllRequired && item.isValid
    )
    props.onCheckValid(isAllValid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions])

  return (
    <SectionLayout
      title="Conditionnements"
      action={
        <Action
          isDisabledQTEToggle={isDisabledQTEToggle}
          isUseQteVariable={props.qteVariable}
          isDisablePlusBtn={
            props.mode === MODE.VIEW ||
            !(
              actions[actions.length - 1]?.isForceValid &&
              actions[actions.length - 1]?.isValid
            ) ||
            data.length >= 99 ||
            disableLines.length !== data.length
          }
          isDisableModel={props?.mode === MODE.VIEW}
          isDisableVersion={props?.mode === MODE.VIEW}
          onToggleQTE={onToggleQTE}
          onClickPlusBtn={onAddNewLine}
          dataLength={data.length}
        />
      }
      alert={props?.alert}
    >
      <Spin spinning={isLoading}>
        <div className="overflow-y-hidden pb-2 detail-scroll">
          <ITable
            resetFlags={resetFlags}
            formatCols={formatCols}
            formatRows={formatRows}
            header={header}
            data={data}
            actions={props.mode !== MODE.VIEW ? actions : []}
            disabledLines={disableLines}
            onChangeDataTable={onChangeDataTable}
            onCheckInvalid={onCheckInvalid}
            onCheckFillRequired={onCheckFillRequired}
            forceShowError={forceShowError}
            forceShowWarning={forceShowWarning}
            hideCols={hideCols}
            observeAndTransform={observeAndTransform}
            forceReset={[]}
            name="conditionements"
            actionNode={(
              index: number,
              actionParam?: ActionParam
            ): ReactNode => {
              if (props.mode === MODE.VIEW) return false
              const {
                isValid = false,
                isFillAllRequired = false,
                isAllowDelete = true,
                isForceValid = false,
                isAllowEdit = true,
              } = actionParam || {
                isValid: false,
                isFillAllRequired: false,
                isAllowDelete: true,
                isForceValid: false,
                isAllowEdit: true,
              }
              return (
                <div>
                  <Row gutter={8} align="middle">
                    <Col span={12}>
                      {(!isFillAllRequired || !isValid) && (
                        <i className="icon-valid-disable"></i>
                      )}
                      {isFillAllRequired && isValid && !isForceValid && (
                        <i
                          onClick={() => onCheckValidByBtn(index)}
                          className="icon-valid focus:rounded-2xl"
                          data-previous-id={`conditionementssupport-${index}`}
                          data-next-id={`conditionementsicon-delete-${index}`}
                          id={`conditionementsicon-valid-${index}`}
                          tabIndex={0}
                        />
                      )}
                      {isFillAllRequired &&
                        isValid &&
                        isForceValid &&
                        isAllowEdit && (
                          <i
                            className="icon-edit"
                            id={`conditionementsicon-edit-${index}`}
                            data-previous-id={`conditionementssupport-${index}, conditionementsicon-edit-${
                              index - 1
                            }, ${stockKey['version']}`}
                            data-next-id={`conditionementsicon-delete-${index}, conditionementsicon-edit-${
                              index + 1
                            }`}
                            tabIndex={0}
                            onClick={() => onAllowEdit(index)}
                          />
                        )}
                      {isFillAllRequired &&
                        isValid &&
                        isForceValid &&
                        !isAllowEdit && <i className="icon-edit-disable" />}
                    </Col>
                    <Col span={12}>
                      {isAllowDelete && (
                        <Popconfirm
                          icon={null}
                          title="Êtes-vous sûr de vouloir supprimer cet élément?"
                          description=""
                          okText="Supprimer"
                          okButtonProps={{ className: 'ok-btn' }}
                          cancelButtonProps={{ className: 'cancel-btn' }}
                          cancelText="Annuler"
                          onConfirm={() => onDeleteLine(index)}
                          onCancel={() =>
                            focusById(`conditionementsicon-delete-${index}`)
                          }
                        >
                          <i
                            className="icon-delete focus:rounded-2xl"
                            tabIndex={0}
                            id={`conditionementsicon-delete-${index}`}
                            data-previous-id={`conditionementsicon-valid-${index}, conditionementsicon-edit-${index}, conditionementssupport-${index}`}
                            data-next-id={`fournisseurnom-0, fournisseuricon-edit-0, ${stockKey['DLUO']}, ${stockKey['Chargement']}, ${stockKey['Supprimer']}, ${stockKey['Sauvegarder']}`}
                          ></i>
                        </Popconfirm>
                      )}
                      {!isAllowDelete && (
                        <i className="icon-delete-disable"></i>
                      )}
                    </Col>
                  </Row>
                </div>
              )
            }}
          />
        </div>
      </Spin>
    </SectionLayout>
  )
}

export default Packing
