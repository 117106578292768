import '../../stock/stockscreen/component/wrapper.scss'
import { ChangeEvent, FC, useCallback, useEffect } from 'react'
import { NameAndValue } from '../stockscreen/component/Wrapper'
import { isOnlyContainsNumber } from '../../../utils'
import clsx from 'clsx'

const VarianteInput: FC<{
  onChange(data: NameAndValue): void
  value: string
  nextId: string
  previousId: string
  disabled: boolean
  warningMessage?: string
}> = (props) => {
  const { onChange, value, nextId, previousId, warningMessage, ...rest } = props
  const handleChange = useCallback(() => {
    if (!value || value.length === 0) {
      onChange({ name: 'variante', value: '00' })
    } else if (value.length === 1) {
      onChange({ name: 'variante', value: `0${value}` })
    }
  }, [value])

  useEffect(() => {
    document.addEventListener('focusout', handleChange)
    return () => {
      document.removeEventListener('focusout', handleChange)
    }
  }, [handleChange])

  function onChangeText(e: ChangeEvent<HTMLInputElement>) {
    const val = e.target.value
    if (isOnlyContainsNumber(val) || val.length === 0) {
      onChange({ name: 'variante', value: val })
    }
  }

  return (
    <div>
      <input
        {...rest}
        data-next-id={nextId}
        data-previous-id={previousId}
        value={value}
        maxLength={2}
        onChange={onChangeText}
        className={clsx(
          'css-for-input ol-input w-24',
          props.disabled && 'cursor-no-drop'
        )}
        style={{
          borderColor: warningMessage ? '#FFA500' : '',
        }}
      />
      {warningMessage ? (
        <div className="warning-message text-left break-words min-h-fit">
          <p className="p-0 m-0 break-words">{warningMessage}</p>
        </div>
      ) : null}
    </div>
  )
}

export default VarianteInput
