import { Col, Row } from 'antd'
import { useRef, useState } from 'react'
import { useOnClickOutside } from 'usehooks-ts'
import './select-list.scss'
import { OptionType } from '../../../features/stock/stockscreen/component/model'

export interface OptionItem {
  value?: string
  label?: string
}

interface SelectListProps {
  /**
   * trigger when selected value change
   */
  onChange?: Function
  /**
   * List options of Select List
   */
  options?: OptionType[]
  /**
   * Placeholder of select list
   */
  placeholder?: string
  /**
   * this is selected value of select list
   */
  defaultSelectedValue?: string
  /**
   * this is width (px) of select
   */
  width?: number
  /**
   * Name of select list
   */
  name?: string
  /**
   * to customize style of select list
   */
  className?: string

  disabled?: boolean
  id?: string
  nextId?: string
  previousId?: string
}

const defaultFunction = function () {}

/**
 *
 * The @SelectList is common Select for this app
 * @version 0.1
 * @author [chiendd]
 *
 */
const SelectList = ({
  onChange = defaultFunction,
  options = [],
  placeholder = 'Choose option',
  defaultSelectedValue = '',
  width = 223,
  name = '',
  className = '',
  disabled = false,
  id,
  nextId,
  previousId,
}: SelectListProps) => {
  const [isOpenList, setIsOpenList] = useState(false)
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    function (): string | undefined {
      if (options.length === 0) return undefined
      else if (defaultSelectedValue) {
        const isContainDefaultSelected = options
          .map((item) => item.value)
          .includes(defaultSelectedValue)
        if (isContainDefaultSelected) return defaultSelectedValue
        return undefined
      }
      return options[0]?.value || undefined
    }
  )
  const ref = useRef(null)
  const handleClickOutSide = () => {
    setIsOpenList(false)
  }
  const onClick = () => {
    if (!disabled) setIsOpenList(!isOpenList)
  }
  const onSelectItem = (idx: number) => {
    const value = options[idx]?.value || undefined
    setSelectedValue(value)
    onChange({ name, value })
  }
  const showSelectedLabel = () => {
    const selectedItem = options.find((item) => {
      return item.value === selectedValue
    })
    return selectedItem?.label
  }

  useOnClickOutside(ref, handleClickOutSide)
  return (
    <div
      tabIndex={0}
      id={id}
      data-next-id={nextId}
      data-previous-id={previousId}
      className={`select-list cursor-pointer relative focus:border-solid focus:border-secondary focus:border-[1px] ${className} ${
        disabled ? 'cursor-not-allowed' : ''
      }`}
      style={{ width }}
      ref={ref}
      onClick={onClick}
    >
      <Row className="label-side">
        <Col span={width > 200 ? 20 : 16} className="bd-right-select-list-icon">
          <span className="label">{showSelectedLabel() || placeholder}</span>
        </Col>
        <Col span={width > 200 ? 4 : 8} className="text-center">
          {isOpenList ? (
            <i className={`icon-arrow-up`}></i>
          ) : (
            <i className={`icon-arrow-down`}></i>
          )}
        </Col>
      </Row>
      <ul className={`dropdown-list top-5 w-100 ${isOpenList ? 'open' : ''}`}>
        {options.map((option, idx) => {
          return (
            <li
              onClick={() => onSelectItem(idx)}
              key={idx}
              className="px-4 py-1.5 b"
            >
              <div
                className={`${
                  idx < options.length - 1 ? 'bd-bot-select-list-item' : ''
                }`}
              >
                {option.label}
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default SelectList
