import { Form } from 'antd'
import { useForm } from 'antd/es/form/Form'
import TextArea from 'antd/es/input/TextArea'
import { useEffect, useState } from 'react'
import { MODE } from '../../../../../enum/mode'
import SectionLayout from '../SectionLayout'
import './Commentaire.scss'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../app/store'

//commentaire state
export interface FormCommentaireStateInterface {
  com_chargement: {
    comment?: string
    id?: string
    reference_id?: string
  }
  com_livraison: {
    comment: string
    id?: string
    reference_id?: string
  }
  com_reception: {
    comment: string
    id?: string
    reference_id?: string
  }
}

interface CommentaireProps {
  /**
   * update data when each input change
   * @param newValue new value of each input change
   * @returns void
   */
  onChangeData?: (newValue: FormCommentaireStateInterface) => void
  /**
   * init commentaire data
   */
  initData?: FormCommentaireStateInterface
  /**
   * disable if is view mode
   */
  mode: MODE
}

/**
 * The @Commentaire is a component includes comment of Chargement, Livraison, Réception. Each has max 200 digits.
 * @author [anhnq]
 * @version 0.1
 */
const Commentaire = (props: CommentaireProps) => {
  const stockKey = useSelector((state: RootState) => state.selector).data.stock
  const { onChangeData = () => {}, initData, mode } = props
  const [form] = useForm()
  const [formState, setFormState] = useState<FormCommentaireStateInterface>({
    com_chargement: {
      comment: '',
    },
    com_livraison: {
      comment: '',
    },
    com_reception: {
      comment: '',
    },
  })

  //update data of commentaire when a field changes
  useEffect(() => {
    onChangeData(formState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState])

  useEffect(() => {
    if (!initData) return
    setFormState({
      com_chargement: {
        comment: initData.com_chargement.comment,
      },
      com_livraison: {
        comment: initData.com_livraison.comment,
      },
      com_reception: {
        comment: initData.com_reception.comment,
      },
    })
    form.setFieldsValue({
      com_chargement: initData.com_chargement.comment,
      com_livraison: initData.com_livraison.comment,
      com_reception: initData.com_reception.comment,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initData])

  //update form when textarea changes
  const onChangeTextArea = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    name: string
  ) => {
    setFormState({
      ...formState,
      [name]: {
        comment: e.target.value,
      },
    })
  }

  return (
    <SectionLayout title="Commentaire">
      <Form name="commentaire" form={form} autoComplete="off">
        <div className={`flex ${mode === MODE.EDIT && 'mb-20'}`}>
          <Form.Item
            name="com_chargement"
            label="Chargement"
            colon={false}
            className="font-semibold commentaire-label"
          >
            <TextArea
              style={{
                height: 100,
                width: 330,
                resize: 'none',
              }}
              id={stockKey['Chargement']}
              data-next-id={stockKey['Livraison']}
              data-previous-id={stockKey['Blocage-sortie']}
              className="custom-textarea"
              showCount
              maxLength={200}
              onChange={(e) => onChangeTextArea(e, 'com_chargement')}
              disabled={mode === MODE.VIEW}
            />
          </Form.Item>

          <Form.Item
            name="com_livraison"
            label="Livraison"
            colon={false}
            className="ml-10 font-semibold commentaire-label"
          >
            <TextArea
              style={{
                height: 100,
                width: 330,
                resize: 'none',
              }}
              id={stockKey['Livraison']}
              data-next-id={stockKey['Réception']}
              data-previous-id={stockKey['Chargement']}
              showCount
              maxLength={200}
              onChange={(e) => onChangeTextArea(e, 'com_livraison')}
              value="a"
              disabled={mode === MODE.VIEW}
            />
          </Form.Item>
          <Form.Item
            name="com_reception"
            label="Réception"
            colon={false}
            className="ml-10 font-semibold commentaire-label"
          >
            <TextArea
              style={{
                height: 100,
                width: 330,
                resize: 'none',
              }}
              id={stockKey['Réception']}
              data-next-id={`${stockKey['Supprimer']}, ${stockKey['Sauvegarder']}`}
              data-previous-id={stockKey['Livraison']}
              className="custom-textarea"
              showCount
              maxLength={200}
              onChange={(e) => onChangeTextArea(e, 'com_reception')}
              disabled={mode === MODE.VIEW}
            />
          </Form.Item>
        </div>
      </Form>
    </SectionLayout>
  )
}

export default Commentaire
